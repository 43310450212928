import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/selectTask.css';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import moment from 'moment';
const Swal = require('sweetalert2');

const MyTasks = () => {
  const sessionEmail = sessionStorage.getItem('email');
  const [users, setUsers] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState({});
  const [activeTab, setActiveTab] = useState("pending"); // "pending" or "completed"

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email: sessionEmail },
        });
        setEmployeeData(response.data[0]);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    if (sessionEmail) {
      fetchEmployeeData();
    }
  }, [sessionEmail]);

  const empid = employeeData?.empid;

  const fetchemployeetasks = async () => {
    try {
      const pendingResponse = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/showSpecificEmployeeTasks', { empid });
      const completedResponse = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/showSpecificEmployeeTasksCompleted', { empid });

      setUsers(groupTasks(pendingResponse.data));
      setCompletedTasks(groupTasks(completedResponse.data));
    } catch (error) {
      Swal.fire('Error', 'There was an error fetching tasks!', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (empid) {
      fetchemployeetasks();
    }
  }, [empid]);

  const handleComplete = (ids) => {
    const uniqueIds = [...new Set(ids.flat())];
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to mark these tasks as completed?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Complete it!',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.put(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/completeAssignTask`, { ids: uniqueIds });
  
          Swal.fire('Completed!', 'The tasks have been completed.', 'success').then(() => {
            fetchemployeetasks();
          });
        } catch (error) {
          Swal.fire('Error', 'Failed to complete the tasks!', 'error');
        }
      }
    });
  };
  

  const groupTasks = (tasks) => {
    console.log(tasks);
  
    const grouped = tasks.reduce((acc, task) => {
      const key = `${task.empid}-${task.clientid}-${task.mainTask}-${task.remarks || '-'}`;
      
      if (!acc[key]) {
        acc[key] = {
          ids: [task.ID], // Store the first task ID in an array
          empid: task.empid[0],
          FirstName: task.FirstName,
          LastName: task.LastName,
          clientid: task.clientid,
          TradeName: task.TradeName,
          mainTask: task.mainTask || '-',
          taskname: [task.taskname],
          AssignedBy: task.AssignedBy,
          deadline: task.deadline ? moment(task.deadline).format('DD-MM-YYYY') : '-',
          remarks: task.remarks || '-',
          completedOn : task.completedOn
        };
      } else {
        acc[key].taskname.push(task.taskname);
        acc[key].ids.push(task.ID); // Append additional task IDs to the array
      }
      
      return acc;
    }, {});
  
    return Object.values(grouped); // 🔥 Return as an array instead of an object
  };
  
  

  const columns = useMemo(
    () => [
      { Header: 'CC', accessor: 'clientid' },
      { Header: 'Trade Name', accessor: 'TradeName' },
      { Header: 'Main Task', accessor: 'mainTask' },
      { Header: 'Sub Task', accessor: (row) => row.taskname.join(', ') },
      { Header: 'Remarks', accessor: 'remarks' },
      { Header: 'Deadline', accessor: 'deadline' },
      { Header: 'Assigned By', accessor: 'AssignedBy' },
      activeTab === 'pending'
        ? {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
              <button className="btn btn-success" onClick={() => handleComplete(row.original.ids)}>
                Complete
              </button>
            ),
          }
        : { Header: 'Completed On', accessor: (row) => moment(row.completedOn).format('DD-MMM-YY') },
    ].filter(Boolean),
    [activeTab]
  );

  const tableData = useMemo(() => (Array.isArray(activeTab === 'pending' ? users : completedTasks) 
  ? activeTab === 'pending' ? users : completedTasks 
  : []), [activeTab, users, completedTasks]);

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  setGlobalFilter,
  canPreviousPage,
  canNextPage,
  pageOptions,
  nextPage,
  previousPage,
  state: { pageIndex, pageSize },
  setPageSize,
} = useTable(
  {
    columns,
    data: tableData, // ✅ Always an array
    initialState: { pageIndex: 0, pageSize: 5 },
  },
  useGlobalFilter,
  usePagination
);


  return (
    <>
      <div className="container mt-5">
        <h3>Assigned Tasks</h3>

        {/* Tabs */}
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'pending' ? 'active' : ''}`}
              onClick={() => setActiveTab('pending')}
            >
              Pending Tasks
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'completed' ? 'active' : ''}`}
              onClick={() => setActiveTab('completed')}
            >
              Completed Tasks
            </button>
          </li>
        </ul>

        <div className="search-bar my-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            onChange={(e) => setGlobalFilter(e.target.value || undefined)}
          />
        </div>

        <table {...getTableProps()} className="table table-striped">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="d-flex justify-content-between align-items-center">
          <button className="btn btn-secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </button>
          <span>
            Page <strong>{pageIndex + 1} of {pageOptions.length}</strong>
          </span>
          <button className="btn btn-secondary" onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </button>
          <select className="form-select w-auto" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
            {[5, 10, 15, 20].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default MyTasks;
