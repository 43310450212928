import React, { useState, useEffect } from "react";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt , faPlus, faCheck} from '@fortawesome/free-solid-svg-icons';

const ExceptionalClientReport = () => {
  const [clients, setClients] = useState([]);
  const email = sessionStorage.getItem('email');
  const [employeeData, setEmployeeData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [rows, setRows] = useState([
    {
      client: null,
      exception: "",
      fromDate: "",
      toDate: "",
      submittedBy: "",
      actionReview: "",
      actionTakenBy: "",
      submittedOn:"",
      actionOn: "",
    }
  ]);
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsResponse = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/selectclients');
        const clientOptions = clientsResponse.data.map(client => ({
            value: client.CC,
            label: `${client.CC} - ${client.TradeName}`,
          }));
          setClients(clientOptions);
      } catch (error) {
        Swal.fire('Error', 'Failed to fetch clients', 'error');
      }
    };

    const fetchEmployeeData = async () => {
      try {
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email },
        });
        setEmployeeData(response.data[0]);
      } catch (error) {
        console.error('Error fetching employee data', error);
      }
    };

    fetchClients();
    fetchEmployeeData();
  }, [email]);
  const isMGEmployee = employeeData?.empid?.startsWith('MG');

  const fetchExceptions = async () => {
    try {
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getExceptions');
      const fetchedRows = response.data.map((exception) => ({
        id: exception.id,
        client: { value: exception.cc, label: `${exception.cc} - ${exception.tradeName}` },
        exception: exception.exception,
        actionReview: exception.review,
        fromDate: exception.from ? new Date(exception.from).toISOString().split("T")[0] : null, // Format: YYYY-MM-DD
        toDate: exception.to ? new Date(exception.to).toISOString().split("T")[0] : null, // Format: YYYY-MM-DD
        submittedBy: exception.submittedBy,
        submittedOn: exception.submittedOn ? new Date(exception.submittedOn).toLocaleString() : null, // Readable format
        actionTakenBy: exception.actionBy,
        actionOn: exception.actionOn ? new Date(exception.actionOn).toLocaleString() : null, // Readable format
    }));    
      setRows(fetchedRows);
    } catch (error) {
      console.error('Failed to fetch payment overdue data:', error);
    }
  };

  useEffect(() => {
    fetchExceptions();
  }, []);

  const handleChange = (index, name, value) => {
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
  };

  const handleSelectChange = (index, selectedOption) => {
    const newRows = [...rows];
    newRows[index].client = selectedOption;
    setRows(newRows);
  };

  const addRow = () => {
    setRows([...rows, {
      client: null,
      exception: "",
      fromDate: "",
      toDate: "",
      submittedBy: "",
      actionReview: "",
      actionTakenBy: "",
      submittedOn:"",
      actionOn: "",
    }]);
  };

  const handleDeleteRow = (rowId) => {
    Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel"
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deleteException/${rowId}`)
                .then(() => {
                    Swal.fire({
                        toast: true,
                        text: `Exception deleted successfully`,
                        icon: 'success',
                        position: 'top-end',
                        timer: 1000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                    });
                    fetchExceptions(); // Refresh the list after deletion
                })
                .catch(() => {
                    Swal.fire('Error', 'Failed to delete row', 'error');
                });
        }
    });
};


  const handleSubmitException = async(row) => {  
      if (!row.exception || !row.fromDate || !row.toDate) {
        Swal.fire('Error', 'Please enter exception and time period', 'error');
        return;
      }
  
      const TradeName = row.client.label.split(' - ')[1];
  
      const dataToSubmit = {
        CC: row.client.value,
        TradeName: TradeName,
        exception: row.exception,
        fromDate: row.fromDate,
        toDate: row.toDate,
        empid: employeeData?.empid || '',
      };
  
      try {
        await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/addException', dataToSubmit);
        Swal.fire({
            toast: true,
            text: `Exception submitted successfully`,
            icon: 'success',
          position: 'top-end',
          timer: 1000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        addRow();
        fetchExceptions();
    } catch (error) {
        Swal.fire('Error', 'Failed to submit data', 'error');
      }
    };

  const handleSubmitActionReview = async(row) => {
    if (!row.actionReview) {
        Swal.fire('Error', 'Please Enter the review', 'error');
        return;
      }
  
      const dataToSubmit = {
        id:row.id,
        review: row.actionReview,
        empid: employeeData?.empid || '',
      };
  
      try {
        await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateException', dataToSubmit);
        Swal.fire({
            toast: true,
            text: `Review submitted successfully`,
            icon: 'success',
            position: 'top-end',
            timer: 1000,
            timerProgressBar: true,
            showConfirmButton: false,
        });
        fetchExceptions();
        addRow();
      } catch (error) {
        Swal.fire('Error', 'Failed to submit data', 'error');
      }
    };
    const filteredRows = rows.filter((row) => {
        return (
          (row.client?.label?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
          (row.exception?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
          (row.submittedBy?.toLowerCase() || "").includes(searchTerm.toLowerCase())
        );
      });

  return (
    <div className="formcontainer mt-5">
      <h2 className="mb-3">Client Exceptional Report</h2>
      <div className="table-responsive" style={{overflow:'visible'}}>
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by Client, Exception, or Submitted By..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <table className="table table-striped">
        <thead style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
          <tr>
            <th style={{ width: '190px' }}>Client</th>
            <th style={{ width: '120px' }}>From</th>
            <th style={{ width: '120px' }}>To</th>
            <th style={{ width: '190px' }}>Description of Exception</th>
            <th>#</th>
            <th>Sub By</th>
            <th style={{ width: '190px' }}>Action to be Taken</th>
            <th>#</th>
            <th>Rwd By</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
          {filteredRows .map((row, index) => (
            <tr key={index}>
              <td>
                <Select
                  options={clients}
                  value={row.client}
                  onChange={(selectedOption) => handleSelectChange(index, selectedOption)}
                  placeholder="Select Client"
                  isDisabled={row.submittedBy}
                />
              </td>
              <td>
                <input
                  type="date"
                  name="fromDate"
                  value={row.fromDate}
                  onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                  className="form-control"
                  required
                  style={{ fontSize: '13px',width: '120px' }}
                  disabled={row.submittedBy}
                />
              </td>
              <td>
                <input
                  type="date"
                  name="toDate"
                  value={row.toDate}
                  onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                  className="form-control"
                  required
                  style={{ fontSize: '13px',width: '120px' }}
                  disabled={row.submittedBy}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="exception"
                  value={row.exception}
                  onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                  className="form-control"
                  required
                  style={{ fontSize: '13px' }}
                  disabled={row.submittedBy}
                />
              </td>
              <td>
                <button 
                    type="button" 
                    className="btn btn-success btn-sm" 
                    onClick={() => handleSubmitException(row)}
                    disabled={row.submittedBy}>
                    <FontAwesomeIcon icon={faCheck} size="sm" />
                </button>
              </td>
              <td>
                <input
                  type="text"
                  name="submittedBy"
                  value={row.submittedBy}
                  onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                  className="form-control"
                  required
                  style={{ fontSize: '13px' }}
                  readOnly
                />
              </td>
              <td>
                <input
                  type="text"
                  name="actionReview"
                  value={row.actionReview}
                  onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                  className="form-control"
                  style={{ fontSize: '13px', width: '190px' }}
                  disabled={!isMGEmployee}
                />
              </td>
              <td className="pt-0">
                <button 
                type="button" 
                className="btn btn-success btn-sm" 
                onClick={() => handleSubmitActionReview(row)}
                disabled={!isMGEmployee}>
                    <FontAwesomeIcon icon={faCheck} />
                </button>
              </td>
              <td>
                <input
                  type="text"
                  name="actionTakenBy"
                  value={row.actionTakenBy}
                  className="form-control"
                  required
                  style={{ fontSize: '13px' }}
                  readOnly
                />
              </td>
              <td>
                <button 
                type="button" 
                className="btn btn-danger btn-sm" 
                onClick={() => handleDeleteRow(row.id)} 
                disabled={!isMGEmployee}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button type="button" className="btn btn-success" onClick={addRow}>Add Row</button>
      </div>
    </div>
  );
};

export default ExceptionalClientReport;
