import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/selectTask.css';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
const Swal = require('sweetalert2');

const AssignTask = () => {
  const [employees, setEmployees] = useState([]);
  const [clients, setClients] = useState([]);
  const [mainTasks, setMainTasks] = useState([]); // State for Main Tasks
  const [tasks, setTasks] = useState([]); // State for Sub Tasks
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedMainTask, setSelectedMainTask] = useState(null); // State for selected Main Task
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [taskSelection, setTaskSelection] = useState(null);
  const [remarks, setRemarks] = useState(''); // New field for remarks
  const [deadline, setdeadline] = useState(''); // New field for remarks
  const sessionEmail = sessionStorage.getItem('email');
  const [employeeData, setEmployeeData] = useState({});
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email: sessionEmail },
        });
        const employeeData = response.data[0];
        setEmployeeData(employeeData);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    if (sessionEmail) {
      fetchEmployeeData();
    }
  }, [sessionEmail]);
  useEffect(() => {
    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/selectemployee')
      .then(response => {
        const employeeOptions = response.data.map(employee => ({
          value: employee.empid,
          label: `${employee.empid} ${employee.FirstName} ${employee.LastName}`
        }));
        setEmployees(employeeOptions);
      })
      .catch(error => toast.error('Failed to fetch employees'));

    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/selectclients')
      .then(response => {
        const clientOptions = response.data.map(client => ({
          value: client.CC,
          label: `${client.CC} ${client.TradeName}`
        }));
        setClients(clientOptions);
      })
      .catch(error => toast.error('Failed to fetch clients'));
  }, []);

  // Fetch Main Tasks
  useEffect(() => {
    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getmaintasks')
      .then(response => {
        const mainTaskOptions = response.data.map(task => ({
          value: task.MainTasks,
          label: task.MainTasks
        }));
        setMainTasks(mainTaskOptions);
      })
      .catch(error => toast.error('Failed to fetch main tasks'));
  }, []);

  // Fetch Sub Tasks when Main Task is selected
  useEffect(() => {
    if (selectedMainTask) {
      axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getsubtasks', { maintasks: selectedMainTask.value })
        .then(response => {
          const subTaskOptions = response.data.map(task => ({
            value: task.SubTasks,
            label: task.SubTasks
          }));
          setTasks(subTaskOptions);
        })
        .catch(error => toast.error('Failed to fetch sub-tasks'));
    }
  }, [selectedMainTask]);

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption);
    setSelectedClient(null); // Reset client and tasks when changing employee
    setSelectedTasks([]);
  };

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    setSelectedTasks([]); // Reset tasks when changing client
  };

  const handleTaskChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedTasks(prev => [...prev, selectedOption]);
      setTasks(prev => prev.filter(task => task.value !== selectedOption.value));
      setTaskSelection(null); // Clear selection
    }
  };

  const handleRemoveTask = (taskId) => {
    const removedTask = selectedTasks.find(task => task.value === taskId);
    setSelectedTasks(prev => prev.filter(task => task.value !== taskId));
    setTasks(prev => [...prev, removedTask]);
  };

  const renderTaskBadges = () => {
    return selectedTasks.map(task => (
      <span key={task.value} className="badge badge-primary me-2 mb-2 task-badge">
        {task.label}
        <button
          type="button"
          className="btn btn-danger btn-sm ms-2 "
          onClick={() => handleRemoveTask(task.value)}
        >
          ×
        </button>
      </span>
    ));
  };

  const handleSave = () => {
    if (!selectedEmployee || !selectedClient || !selectedMainTask || selectedTasks.length === 0) {
      toast.error('Please select an employee, a client, a main task, and at least one sub-task');
      return;
    }

    const data = {
      email: sessionEmail,
      empid: selectedEmployee.value,
      clientCode: selectedClient.value,
      mainTask: selectedMainTask.label, // Include Main Task
      tasks: selectedTasks.map(task => ({
        taskname: task.label,
      })),
      remarks, 
      deadline,
    };

    axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/savetaskdetails', data)
      .then(response => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: response.data,
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          window.location.reload(); 
        });
      })
      .catch(error => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: error.message,
          showConfirmButton: false,
          timer: 3000
        });
      });
  };

  return (
    <>
    <div className="container mt-5">
      <ToastContainer />
      <p className='para-heading'>Assign Tasks</p>
      <div className="row">
      <div className="form-conrol col-md-4">
        <label htmlFor="employeeSelect" className="form-label">Select Team Member</label>
        <Select
          id="employeeSelect"
          value={selectedEmployee}
          onChange={handleEmployeeChange}
          options={employees}
          placeholder="Select Team Member"
        />
      </div>
    
        <div className="form-conrol col-md-4">
          <label htmlFor="clientSelect" className="form-label">Select Client</label>
          <Select
            id="clientSelect"
            value={selectedClient}
            onChange={handleClientChange}
            options={clients}
            placeholder="Select Client"
          />
        </div>
       
        <div className="form-conrol col-md-4">
        <label htmlFor="deadline" className="form-label">Deadline</label>
        <input
          type="date"
          className="form-control"
          id="deadline"
          value={deadline}
          onChange={(e) => setdeadline(e.target.value)}
        />
      </div>
      </div>
      <div className="row">
        <div className="form-conrol col-md-4">
            <label htmlFor="mainTaskSelect" className="form-label">Select Main Task</label>
            <Select
              id="mainTaskSelect"
              value={selectedMainTask}
              onChange={(option) => {
                setSelectedMainTask(option);
                setSelectedTasks([]); // Reset selected sub-tasks when main task changes
              }}
              options={mainTasks}
              placeholder="Select Main Task"
            />
          </div>
         
          <div className="form-conrol col-md-4">
              <label htmlFor="subTaskSelect" className="form-label">Select Sub Tasks</label>
              <Select
                id="subTaskSelect"
                value={taskSelection}
                onChange={handleTaskChange}
                options={tasks}
                placeholder="Select Sub Task"
              />
            </div>
    
    <div className="form-conrol col-md-4">
        <label htmlFor="remarks" className="form-label">Remarks</label>
        <input
          type="text"
          className="form-control"
          id="remarks"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          placeholder="Enter remarks (optional)"
        />
      </div>
      {selectedTasks.length > 0 && (
        <div className="mb-3">
          <label className="form-label">Selected Sub Tasks:</label>
          <div>{renderTaskBadges()}</div>
        </div>
      )}
      </div>
      <button className="btn btn-success mt-3" onClick={handleSave}>Assign Task </button>
    </div>
  </>
  );
};

export default AssignTask;
