import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt , faPlus, faCheck} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

const PaymentOverdueForm = () => {
  const [clients, setClients] = useState([]);
  const email = sessionStorage.getItem('email');
  const [employeeData, setEmployeeData] = useState({});
  const [rows, setRows] = useState([
    {
      id: 1, 
      client: null, 
      billAmount: "", 
      taxAmount: "",
      receivedTaxAmount:"", 
      totalAmount: "",
      totalReceivedAmount:"", 
      balance:"",
      receivedAmount: "", 
      reason: null, 
      totalSubmitted: false,
      isreceived: false,
      dueRemarks: "",
      recdRemarks: "",
      balance:""
    }
  ]);

  const isMGEmployee = employeeData?.empid?.startsWith('MG') || employeeData?.empid?.startsWith('TM-003');

  const reasonOptions = [
    { value: 'Recd full payment', label: 'Recd full payment' },
    { value: 'Recd part payment', label: 'Recd part payment' },
    { value: 'Recd only tax amount', label: 'Recd only tax amount' },
    { value: 'Will pay with next GSTR', label: 'Will pay with next GSTR' },
    { value: 'Will pay with next TDS/TCS challan', label: 'Will pay with next TDS/TCS challan' },
    { value: 'Will pay in next week', label: 'Will pay in next week' },
    { value: 'Will pay in 3-4 days', label: 'Will pay in 3-4 days' },
    { value: 'Will pay in month', label: 'Will pay in month' },
    { value: 'No response from client', label: 'No response from client' },
    { value: 'Made reminder one', label: 'Made reminder one' },
    { value: 'Made reminder two', label: 'Made reminder two' },
    { value: 'Made reminder three', label: 'Made reminder three' }
  ];

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsResponse = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/selectclients');
        setClients(clientsResponse.data);
      } catch (error) {
        Swal.fire('Error', 'Failed to fetch clients', 'error');
      }
    };

    const fetchEmployeeData = async () => {
      try {
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email },
        });
        setEmployeeData(response.data[0]);
      } catch (error) {
        console.error('Error fetching employee data', error);
      }
    };

    fetchClients();
    fetchEmployeeData();
  }, [email]);

  const fetchPaymentOverdue = async () => {
    try {
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getpaymentoverdue');
      const fetchedRows = response.data.map((payment, index) => ({
        id: index + 1,
        client: { value: payment.CC, label: `${payment.CC} - ${payment.TradeName}` },
        billAmount: payment.billAmount,
        taxAmount: payment.taxAmount,
        totalAmount: payment.totalAmount,
        receivedAmount: payment.receivedAmount,
        DueSubmittedBy:payment.DueSubmittedBy,
        DueSubmittedDate:payment.DueSubmittedDate,
        receivedSubmittedBy:payment.receivedSubmittedBy,
        ReceivedSubmittedDate:payment.ReceivedSubmittedDate,
        reason: { value: payment.reason, label: payment.reason },
        receivedTaxAmount:payment.receivedTaxAmount,
        totalReceivedAmount:payment.totalReceivedAmount,
        dueRemarks:payment.dueRemarks,
        recdRemarks: payment.recdRemarks,
        totalSubmitted: true,
        isreceived: !!(payment.receivedAmount && payment.reason),
        balance:payment.balance // Mark as received if both receivedAmount and reason are present
      }));
      setRows(fetchedRows);
    } catch (error) {
      console.error('Failed to fetch payment overdue data:', error);
    }
  };

  useEffect(() => {
    fetchPaymentOverdue();
  }, []);

  const getAvailableClients = (rowId) => {
    const selectedClients = rows
      .filter(row => row.id !== rowId)
      .map(row => row.client?.value);
    return clients
      .filter(client => !selectedClients.includes(client.CC))
      .map(client => ({ value: client.CC, label: `${client.CC} - ${client.TradeName}` }));
  };

  const addRow = () => {
    const newRow = {
      id: rows.length + 1,
      client: null,
      billAmount: "",
      taxAmount: "",
      totalAmount: "",
      receivedAmount: "",
      receivedTaxAmount:"", 
      balance:"",
      reason: null,
      totalSubmitted: false,
      isreceived: false
    };
    setRows([...rows, newRow]);

  };

  const handleClientChange = (selectedClient, rowId) => {
    setRows(rows.map(row => row.id === rowId ? { ...row, client: selectedClient } : row));
  };

  const handleInputChange = (e, rowId, field) => {
    const value = e.target.value;
    setRows(rows.map(row => {
      const billAmount = field === 'billAmount' ? value : Number(row.billAmount);
      const taxAmount = field === 'taxAmount' ? value : (row.taxAmount);
      const receivedAmount=field === 'receivedAmount' ? value : Number(row.receivedAmount);
      const receivedTaxAmount=field ==='receivedTaxAmount'? value : Number(row.receivedTaxAmount);
      // const balanceAmount=field === 'balance' ? value : Number(row.balance);
      const totalAmount = parseFloat(billAmount) + parseFloat(taxAmount);
      const totalReceivedAmount=parseFloat(receivedAmount) + parseFloat(receivedTaxAmount)
      const balance=totalAmount - totalReceivedAmount;
      return row.id === rowId
        ? { ...row, [field]: value, totalAmount: isNaN(totalAmount) ? "" : totalAmount ,totalReceivedAmount:isNaN(totalReceivedAmount) ? "" : totalReceivedAmount,balance : isNaN(balance) ? "" : balance}
        : row;
    }));
  };

  const handleReasonChange = (selectedReason, rowId) => {
    setRows(rows.map(row => row.id === rowId ? { ...row, reason: selectedReason } : row));
  };

  const handleDeleteRow = async (rowId) => {
    const row = rows.find(row => row.id === rowId);
    const clientCC = row?.client?.value;
  
    if (!clientCC) {
      Swal.fire('Error', 'Client not selected', 'error');
      return;
    }
  
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    });
  
    if (result.isConfirmed) {
      try {
        await axios.delete(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deletepaymentoverdue/${clientCC}`);
        
        Swal.fire({
          toast: true,
          text: 'Row deleted successfully',
          icon: 'success',
          position: 'top-end',
          timer: 1000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
  
        fetchPaymentOverdue();
      } catch (error) {
        Swal.fire('Error', 'Failed to delete row', 'error');
      }
    }
  };
  

  const handleSubmitReceived = async (rowId) => {
    const row = rows.find(row => row.id === rowId);
    const dataToSubmit = {
      CC: row.client?.value || '',
      receivedAmount: row.receivedAmount || '',
      reason: row.reason?.value || '',
      empid: employeeData?.empid || '',
      receivedTaxAmount: row.receivedTaxAmount,
      recdRemarks:row.recdRemarks || '',
      totalReceivedAmount:row.totalReceivedAmount || '',
      balance:row.balance
    };

    try {
      await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/submitreceivedamount', dataToSubmit);
      setRows(rows.map(r => r.id === rowId ? { ...r, isreceived: true } : r));
      Swal.fire({
        toast: true,
        text: `Received amount submitted successfully`,
        icon: 'success',
        position: 'top-end',
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      fetchPaymentOverdue();
    } catch (error) {
      Swal.fire('Error', 'Failed to submit received amount', 'error');
    }
  };

  const handleSubmitTotal = async (rowId) => {
    const row = rows.find(row => row.id === rowId);
    if (!row.client || !row.billAmount || !row.taxAmount) {
      Swal.fire('Error', 'Please select a client and enter bill and tax amounts', 'error');
      return;
    }

    const TradeName = row.client.label.split(' - ')[1];

    const dataToSubmit = {
      CC: row.client.value,
      billAmount: row.billAmount,
      taxAmount: row.taxAmount,
      totalAmount: row.totalAmount,
      receivedAmount: row.receivedAmount,
      receivedTaxAmount: row.receivedTaxAmount,
      balance: row.balance,
      reason: row.reason?.value || null,
      empid: employeeData?.empid || '',
      TradeName: TradeName,
      dueRemarks:row.dueRemarks || '',
    };

    try {
      await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/submitpaymentoverdue', dataToSubmit);
      setRows(rows.map(r => r.id === rowId ? { ...r, totalSubmitted: true } : r));
      addRow();
      fetchPaymentOverdue();
      Swal.fire({
        toast: true,
        text: `Data submitted successfully`,
        icon: 'success',
        position: 'top-end',
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      Swal.fire('Error', 'Failed to submit data', 'error');
    }
  };

  return (
    <div className="container">
      <h3 className="mt-5">Payment Overdue Form</h3>

      <table className="table table-bordered 0" >
        <thead className="thead-light">
          <tr>
            <th >Select Client <br /> Reason</th>
            <th >Due Amt <br />Recd Amt</th>
            <th  >Tax Amt <br />Recd tax Amt</th>
            <th  >Total Due <br /> Total Recd</th>
            <th>Balance</th>
            <th>Remarks</th>
            <th>#</th>
            <th >Sub. By <br />Dated</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
          {rows.map((row) => (
            <>
            <tr key={row.id}>
              <td className='col-md-3'>
                <Select
                  value={row.client}
                  onChange={(selectedClient) => handleClientChange(selectedClient, row.id)}
                  options={getAvailableClients(row.id)}

                  styles={{
                    control: (provided) => ({
                      ...provided,
                      // backgroundColor: "transparent", // Removes background
                      // border: "none", // Optional: Add border
                      // boxShadow: "none",
                      fontSize:'14px',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      fontWeight: "bolder", // Makes text bold
                      color: "#1e2cc9", // Ensures text is visible
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontWeight: "bold", // Makes placeholder bold
                      color: "#888", // Optional: Lighter color for placeholder
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: "white", // Keeps dropdown visible
                    }),
                  }}
                  isDisabled={!isMGEmployee || row.totalSubmitted || row.isreceived}
                  isClearable
                />
              </td>
              <td >
                <input
                style={{color: '#1e2cc9' }}
                  type="text"
                  value={row.billAmount}
                  onChange={(e) => handleInputChange(e, row.id, 'billAmount')}
                  disabled={!isMGEmployee || row.totalSubmitted || row.isreceived}
                  className="form-control"
                />
              </td>
              <td>
                <input
                style={{color: '#1e2cc9' }}
                  type="number"
                  value={row.taxAmount}
                  onChange={(e) => handleInputChange(e, row.id, 'taxAmount')}
                  disabled={!isMGEmployee || row.totalSubmitted || row.isreceived}
                  className="form-control"
                />
              </td> 
              <td>
                <input
                style={{color: '#1e2cc9' }}
                  type="number"
                  value={row.totalAmount}
                  disabled
                  className="form-control"
                />
              </td>
              <td></td>
              <td > 
                <input
                style={{color: '#1e2cc9' }}
                  type="text"
                  value={row.dueRemarks}
                  onChange={(e) => handleInputChange(e, row.id, 'dueRemarks')}
                  readOnly={!isMGEmployee || row.isreceived}
                  className="form-control"
                />
              </td>
              <td>
                <button
                  className="btn btn-success"
                  onClick={() => handleSubmitTotal(row.id)}
                  disabled={!isMGEmployee  || row.totalSubmitted}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
              </td>
              <td>
  {row.DueSubmittedBy ? (
    <>
      <div>{row.DueSubmittedBy}</div>
      <div>{moment(row.DueSubmittedDate).format('DD-MM-YY')}</div>
    </>
  ) : (
    ''
  )}
</td>
<td></td>

</tr>
<tr key={row.id} >
              <td>
                <Select
                  value={row.reason}
                  onChange={(selectedReason) => handleReasonChange(selectedReason, row.id)}
                  options={reasonOptions}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      // backgroundColor: "transparent", // Removes background
                      // border: "none", // Optional: Add border
                      // boxShadow: "none",
                      fontSize:'14px',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      fontWeight: "600", // Makes text bold
                      color: "#1e2cc9", // Ensures text is visible
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontWeight: "500", // Makes placeholder bold
                      color: "#888", // Optional: Lighter color for placeholder
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: "white", // Keeps dropdown visible
                    }),
                  }}
                  isDisabled={row.isreceived}
                  isClearable
                />
              </td>
              <td >
                <input
                style={{color: '#1e2cc9'}}
                  type="text"
                  value={row.receivedAmount}
                  onChange={(e) => handleInputChange(e, row.id, 'receivedAmount')}
                  disabled={row.isreceived}
                  className="form-control"
                />
              </td>
              <td>
                <input
                style={{color: '#1e2cc9'}}
                  type="number"
                  value={row.receivedTaxAmount}
                  onChange={(e) => handleInputChange(e, row.id, 'receivedTaxAmount')}
                  disabled={row.isreceived}
                  className="form-control"
                />
              </td> 
              <td>
                <input
                style={{color: '#1e2cc9'}}
                  type="number"
                  value={row.totalReceivedAmount}
                  disabled
                  className="form-control"
                />
              </td>
              <td><input
              style={{color: '#1e2cc9'}}
                  type="number"
                  value={row.balance}
                  onChange={(e) => handleInputChange(e, row.id, 'balance')}
                  disabled
                  className="form-control"
                /></td>
              <td>
                <input
                style={{color: '#1e2cc9'}}
                  type="text"
                  value={row.recdRemarks}
                  onChange={(e) => handleInputChange(e, row.id, 'recdRemarks')}
                  readOnly={row.isreceived}
                  className="form-control"
                />
              </td>
              <td>
                <button
                  className="btn btn-success"
                  onClick={() => handleSubmitReceived(row.id)}
                  disabled={row.isreceived || !row.receivedAmount || !row.reason}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
              </td>
             
              <td className='col-md-1'>
  {row.receivedSubmittedBy ? (
    <>
      <div>{row.receivedSubmittedBy}</div>
      <div>{moment(row.ReceivedSubmittedDate).format('DD-MM-YY')}</div>
    </>
  ) : (
    ''
  )}
</td>


              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDeleteRow(row.id)
                    
                  }
                  disabled={!isMGEmployee}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </td>
            </tr>
            <tr className='table-info'>
              <td colSpan={9} ></td>
            </tr>
            </>
          ))}
          <button className="btn btn-primary mt-3" onClick={addRow}>
        Add New Row
      </button>
        </tbody>
      </table>
    </div>
  );
};

export default PaymentOverdueForm;
