import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useTable, usePagination, useFilters } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDotCircle, faFilter, faInfoCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx'; // Import XLSX for exporting to Excel
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';

const ClientReport = () => {
  const [mainTask, setMainTask] = useState('Books of Accounts');
  const [clients, setClients] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [taskToData, setTaskToData] = useState([]); // State to hold taskto values
  const [loading, setLoading] = useState(true);
  const [selectedColumns, setSelectedColumns] = useState({
    'Sr. No.': true,
    Responsible: true,
    'P' : true,
    CC: true,
    'Trade Name': true,
    'TL':true,
    'L':true,
    'R':true,
  });
  const [searchInput, setSearchInput] = useState('');
  const [responsibleMembers, setResponsibleMembers] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/clients');
        setClients(response.data);
        // Fetch responsible members for all clients
        const clientCCs = response.data.map((client) => client.CC);
        const responsibleData = await fetchResponsibleMembers(clientCCs);
        setResponsibleMembers(responsibleData);
      } catch (error) {
        Swal.fire('Error', 'There was an error fetching clients!', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  // Fetch responsible members for each client
  const fetchResponsibleMembers = async (clientCCs) => {
    const responsibleMap = {};
    for (const CC of clientCCs) {
      try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetchresponsibemember', { CC });
        responsibleMap[CC] = response.data.responsibleMember?.firstname || 'N/A';
      } catch (error) {
        console.error(`Error fetching responsible member for ${CC}:`, error);
        responsibleMap[CC] = 'N/A'; // In case of error, set 'N/A'
      }
    }
    return responsibleMap;
  };

  useEffect(() => {
    const resetSubTasksColumns = () => {
      setSelectedColumns((prevColumns) => {
        const newColumns = {
          'Sr. No.': prevColumns['Sr. No.'],
          'Responsible' : prevColumns['Responsible'],
          'P' : prevColumns['P'],
          CC: prevColumns['CC'],
          'Trade Name': prevColumns['Trade Name'],
          'TL' : prevColumns['TL'],
          'L': prevColumns['L'],
          'R': prevColumns['R'],
        };
        return newColumns;
      });
    };

    const fetchSubTasks = async () => {
      try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getsubtasks', {
          maintasks: mainTask,
        });
        const fetchedSubTasks = response.data;
        setSubTasks(fetchedSubTasks);

        const newSubTaskColumns = {};
        fetchedSubTasks.forEach((task, index) => {
          newSubTaskColumns[task.SubTasks] = index < 6;
        });

        setSelectedColumns((prevColumns) => ({ ...prevColumns, ...newSubTaskColumns }));
      } catch (error) {
        Swal.fire('Error', 'There was an error fetching subtasks!', 'error');
      }
    };

    resetSubTasksColumns();
    fetchSubTasks();
  }, [mainTask]);

  useEffect(() => {
    const fetchTaskToValues = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/latest-taskto');
        setTaskToData(response.data);
        console.log(response.data);
      } catch (error) {
        Swal.fire('Error', 'There was an error fetching taskto values!', 'error');
      }
    };

    fetchTaskToValues();
  }, []);

  const handleMainTaskChange = (e) => {
    setMainTask(e.target.value);
  };

  const handleColumnChange = (e) => {
    const { name, checked } = e.target;
    setSelectedColumns((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };
  const handleSelectAll = () => {
    setSelectedColumns((prevColumns) => {
      const updatedColumns = {};
      Object.keys(prevColumns).forEach((column) => {
        updatedColumns[column] = true;
      });
      return updatedColumns;
    });
  };
  
  const handleUnselectAll = () => {
    setSelectedColumns((prevColumns) => {
      const updatedColumns = {};
      Object.keys(prevColumns).forEach((column) => {
        updatedColumns[column] = false;
      });
      return updatedColumns;
    });
  };
  
  

  const columns = useMemo(
    () => {
      const defaultColumns = [
        { Header: '#', accessor: (_, index) => index + 1, show: selectedColumns['Sr. No.'] },
        { 
          Header: 'Responsible', 
          accessor: 'Responsible', 
          show: selectedColumns['Responsible'],
          Cell: ({ row }) => responsibleMembers[row.original.CC] || 'N/A',  // Display responsible member
        },
        { Header: 'P', accessor: 'Priority', show: selectedColumns['P'] },
        { Header: 'CC', accessor: 'CC', show: selectedColumns['CC'] },
        { Header: 'Trade Name', accessor: 'TradeName', show: selectedColumns['Trade Name'] },
        { Header: 'TL', accessor: 'timeLag', show: selectedColumns['TL'] },
        { Header: 'L', accessor: 'levelOfBOA', show: selectedColumns['L'] },
        { Header: 'R', accessor: 'reportingFrequency', show: selectedColumns['R'] },
      ];
  
      const subTaskColumns = subTasks.map((task) => ({
        Header: task.SubTasks,
        accessor: task.SubTasks,
        show: selectedColumns[task.SubTasks],
        Cell: ({ row, column }) => {
          const cellValue = row.original[task.SubTasks] || '';
          const currentDate = moment();
      
          // Initialize variables to hold style and formatted date
          let cellStyle = {};
          let formattedDate = '';
      
          if (cellValue) {
            const taskDate = moment(cellValue);
            formattedDate = taskDate.format('DD-MMM-YYYY');
      
            // Ensure the date is valid
            if (!taskDate.isValid()) {
              return <div>Invalid Date</div>;
            }
      
            // Calculate the difference in days between the task date and the current date
            const daysDifference = taskDate.diff(currentDate, 'days');
            const timeLag = row.original.timeLag; // Assuming timeLag is part of the row data
      
            // Log the values for debugging
            console.log(`Cell Rendered for SubTask: ${task.SubTasks}`);
            console.log(`Task Date: ${taskDate.format('DD-MMM-YYYY')}`);
            console.log(`Days Difference: ${daysDifference}`);
            console.log(`Time Lag: ${timeLag}`);
            console.log(`Current Date: ${currentDate.format('DD-MMM-YYYY')}`);
      
            // Apply background color based on the difference and timeLag
            if (daysDifference <= timeLag) {
              cellStyle = { backgroundColor: '#28a745', color: 'white' }; // Success
            } else if (daysDifference <= timeLag + 3) {
              cellStyle = { backgroundColor: '#ffc107', color: 'black' }; // Warning
            } else {
              cellStyle = { backgroundColor: '#dc3545', color: 'white' }; // Danger
            }
          }
      
          return (
            <div className="d-flex justify-content-between align-items-center" style={cellStyle}>
              <span>{formattedDate}</span>
              <FontAwesomeIcon
                icon={faCircle}
                className="ms-2"
                style={{ cursor: 'pointer', fontSize: '5px', color: '#178459' }}
                onClick={() => {
                  const cc = row.original.CC;
                  const tradeName = row.original.TradeName;
                  const subTask = task.SubTasks; // SubTask column name
      
                  // Navigate to assignTaskByReports with state
                  navigate('../assignTaskByReports', {
                    state: {
                      cc,
                      tradeName,
                      mainTask,
                      subTask
                    }
                  });
                }}
              />
            </div>
          );
        }
      }));
      
  
      return [...defaultColumns, ...subTaskColumns].filter((column) => column.show);
    },
    [selectedColumns, subTasks, responsibleMembers, navigate]
  );
  

  const filteredData = useMemo(
    () =>
      clients.filter((client) =>
        Object.values(client).some((value) =>
          value !== null && value.toString().toLowerCase().includes(searchInput.toLowerCase())
        )
      ),
    [clients, searchInput]
  );

  const dataWithTaskTo = useMemo(() => {
    return filteredData.map(client => {
      const clientData = { ...client };
      taskToData.forEach(task => {
        if (client.CC === task.CC) {
          clientData[task.subTasks] = task.taskto;
        }
      });
      return clientData;
    });
  }, [filteredData, taskToData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex, pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: dataWithTaskTo,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    usePagination
  );

  const handleExportExcel = () => {
    // Format data exactly as displayed in the table
    const formattedData = dataWithTaskTo.map(row => {
      const formattedRow = {};
  
      // Loop through each column and apply table's rendering logic
      columns.forEach(column => {
        const header = column.Header;
        const cellValue = row[column.accessor] || ""; // Access row value or fallback to empty string
        
        // Add the basic cell value to the export data
        formattedRow[header] = cellValue;
  
        // Check if the column header matches any taskto value in taskToData and add formatted taskto
        taskToData.forEach(task => {
          if (task.CC === row.CC && task.subTask === header) {
            formattedRow[header] += ` ${moment(task.taskto).format('DD-MMM-YYYY')}`;
          }
        });
      });
  
      return formattedRow;
    });
  
    // Create a worksheet from the formatted data
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'H Date Matrix');
  
    // Trigger the download of the Excel file
    XLSX.writeFile(workbook, 'Client_Report.xlsx');
  };
  
  return (
    <div className="container mt-5">
      <h2 className="mb-4">Date Matrix</h2>
      <div className="row mb-3">
        <div className="col-md-6">
          <label htmlFor="mainTask">Select Main Task:</label>
          <select id="mainTask" className="form-control" value={mainTask} onChange={handleMainTaskChange}>
            <option value="Books of Accounts">Books of Accounts</option>
            <option value="GST Compliances">GST Compliances</option>
            <option value="TAX Compliances">TAX Compliances</option>
          </select>
        </div>
        <div className="col-md-6 d-flex justify-content-end align-items-end">
        <div className="dropdown">
  <button
    className="btn btn-secondary dropdown-toggle"
    type="button"
    id="filterDropdown"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <FontAwesomeIcon icon={faFilter} /> Filter Columns
  </button>
  <ul className="dropdown-menu" aria-labelledby="filterDropdown">
    {/* Select All and Unselect All */}
    <li className="dropdown-item">
      <button className="btn btn-link p-0" onClick={handleSelectAll}>
        Select All
      </button>
    </li>
    <li className="dropdown-item">
      <button className="btn btn-link p-0" onClick={handleUnselectAll}>
        Unselect All
      </button>
    </li>
    <li>
      <hr className="dropdown-divider" />
    </li>
    {/* Column checkboxes */}
    {Object.keys(selectedColumns).map((column) => (
      <li key={column} className="dropdown-item">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name={column}
            checked={selectedColumns[column]}
            onChange={handleColumnChange}
            id={`checkbox-${column}`}
          />
          <label
            className="form-check-label"
            htmlFor={`checkbox-${column}`}
            onClick={(e) => e.stopPropagation()} // Stop event propagation
          >
            {column}
          </label>
        </div>
      </li>
    ))}
  </ul>
</div>

</div>
      </div>

      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>

      <button className="btn btn-secondary mb-3" onClick={handleExportExcel}>
        Export to Excel
      </button>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div style={{ display: 'flex', overflowX: 'auto' }}>
<table {...getTableProps()} className="table table-bordered" style={{fontSize:'14px'}}>
          <thead className="thead-dark">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                    {/* Display taskto value for the respective subTask */}
                    {taskToData.map(task => (
                      task.CC === row.original.CC && task.subTask === cell.column.Header ? (
                        <span key={task.taskto}> {moment(task.taskto).format('DD-MMM-YY')}</span>
                      ) : null
                    ))}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        </table>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div>
          <button className="btn btn-secondary me-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </button>
          <button className="btn btn-secondary" onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </button>
        </div>
        <span>
          Page {pageIndex + 1} of {pageOptions.length}
        </span>
        <select
          className="form-select w-auto"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[10, 20, 30, 40, 50].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default ClientReport; 