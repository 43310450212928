import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import '../styles/adminDashboard.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsProgress, faClockRotateLeft,faChartPie, faSignOutAlt, faUserPlus, faList, faHouse, faUser,faCircleDot, faBriefcase, faBusinessTime, faListCheck, faCalendarDays, faBuilding, faBars, faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import LeaveApplicationForm from './leaveApplicationForm';
import ShowRequests from './showemployeereq';
import LateEntryEarlyExitForm from './lateEntryEarlyExitForm';
import DailyWorking from './dailyworking';
import TeamMemberProfile from './teamMemberProfile';
import EmployeeHomePage from './employeeHomePage';
import UserMyClients from './userMyClients';
import ShowEmployeeTasks from './showEmployeeTasks';
import AssignTask from './assignTasks';
import DayInOut from './dayinout';
import Timesheet from './Timesheet';
import ClientVisitTracker from './clientVisitTracker';
import MyCompletedTask from './myCompletedTask';
import FloatingTimesheet from './FloatingTimesheet';
import TimesheetPreviewFixed from './timesheetPreviewFixed';
import TimesheetPreviewFloating from './timesheetPreviewFloating';
import MyTimesheet from './showTimesheetEmployee';
import AddTeamMember from './addTeamMember';
import UserList from './showUsers';
import AddClient from './addClient';
import ClientList from './showClients';
import EmployeeClientSelector from './assignClient';
import Showassignclient from './showassignclients';
import AdminRequests from './adminLeaveRequests';
import AdminAccessRequests from './adminaccessrequests';
import CompletedTaskForAdmin from './completedTaskForAdmin';
import EditTeamMember from './editTeamMember';
import EditClient from './editClient';
import TimesheetListForAdmin from './timesheetListForAdmin';
import FixedTimesheetApproval from './fixedTimesheetApproval';
import FloatingTimesheetApproval from './floatingTimesheetApproval';
import ClientCompletedTaskForAdmin from './clientCompletedTaskForAdmin';
import DayInOutReport from './dayInOutReport';
import VisitReport from './visitReport';
import PendingTaskDailyWorking from './pendingTaskDailyWorking';
import ProductivityAnalysis from './productivityAnalysis';
import ClientReport from './clientReport';
import GSTCredentials from './gstCredentials';
import TaxCredentials from './taxCredentials';
import EWayInvoiceCredentials from './ewayInvoiceCredentials';
import AssignTasksByReport from './assignTasksByReport';
// import PaymentOverdueForm from './paymentOverdueForm';
import TdsTcsCredentials from './tdsTcsCredentials';
import EditDailyWorking from './editDailyWorking';
import VDateMatrix from './vDateMatrix';
import TaskManager from './tasks';
import MemberClientCompletedTask from './memberClientCompletedTask';
import MemberProductivityAnalysis from './memberProductivityAnalysis';
import MemberVisitReport from './memberVisitReport'
import FloatingDailySummary from './FloatingDailySummary';
import FixedDailySummary from './FixedDailySummary';
import DayIn from './dayIn';
import DayOut from './dayOut';
import TeamDailyTaskReport from './teamDailyTaskReport';
import ClientTimeReport from './clientTimeReport';
import PaymentOverdueForm from './PaymentOverdueForm';
import MemberClientTimeReport from './memberClientTimeReport';
import MemberDayInOutReport from './memberDayInOutReport';
import StandardTimeVarianceReport from './standardTimeVarianceReport'
import MemberStandardTimeVariance from './memberStandardTimeVarianceReport';
import TransporterDetails from './transporter';
import ECOMCredentials from './ecomCredentials';
import GSTCredentialsHA from './gstCredentialsH&A';
import SOPForm from './clientSOP'
import GSTCredentialsHY from './gstCredentialsH&A';
import TaskOverview from './taskOverview';
import InvoiceCredentials from './InvoiceCredentials';
import TcsCredentials from './TcsCredentials';
import AddLedger from './AddLedger';
import Billing from './billing';
import Challan from './challan';
import GSTReconPage from './gstRecon';
import DayStatusReport from './dayStatusReport'
import TCSMasterList from './TCSMaster';
import TDSMasterList from './TDSMaster';
import MemberDayStatusReport from './memberDayStatusReport';
import ExceptionalClientReport from './exceptionalClientReport';
import SurplusDeficiencyReport from './surplusDeficincyReport';
import MyTasks from './myTasks';
const Swal = require('sweetalert2');

const UserDashboard = () => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeLink, setActiveLink] = useState('Dashboard');
  const [employeeData, setEmployeeData] = useState({});
  const email = sessionStorage.getItem('email'); // Get email from session storage
   const [searchTerm, setSearchTerm] = useState('');
 const matchesSearch = (label) => label.toLowerCase().includes(searchTerm.toLowerCase());
  const [toggleStates, setToggleStates] = useState({
    ledger: false,
    functions: true,
    assignments: false,
    credentials: false,
    requests: false,
    userTeamReports: false,
    userClientReports: false,
    approvals: false,
    mgUserReports: false,
    mgClientReports: false,
    mgGlobalReports: false,
    clientFunctions: false,
    teamMemberFunctions: false,
    taskManager:false,
    masterList:false,
  });
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata`, {
          params: { email },
        });
        if (response.data && response.data.length > 0) {
          setEmployeeData(response.data[0]); // Assuming response.data is an array with a single user object
        } else {
          console.error('No user data found for the provided email');
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };

    fetchProfileData();
  }, []);
  let isMGEmployee=null;
  if(email){
     isMGEmployee =
    employeeData &&
    employeeData.empid &&
    typeof employeeData.empid === 'string' &&
    employeeData.empid.startsWith('MG');}
    else{
      isMGEmployee=null;
    }
    let isTM003 = null;
    if(email){
      isTM003 =
      employeeData &&
      employeeData.empid &&
      typeof employeeData.empid === 'string' &&
      employeeData.empid.startsWith('TM-003');}
      else{
        isTM003=null;
      }

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleNavHeading = (heading) => {
    setToggleStates((prev) => ({ ...prev, [heading]: !prev[heading] }));
  };
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const handleLogout = () => {
    Swal.fire({
      title: 'Do you want to log out?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      icon: 'warning',
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem('email');
        navigate('/');
      }
    });
  };

    // Monitor changes to dayInDate and respond to changes immediately
    useEffect(() => {
      if (employeeData.dayInDate) {
        console.log('dayInDate changed:', employeeData.dayInDate);
      }
      // Add any additional logic needed for when dayInDate changes
    }, [employeeData.dayInDate]);
  
    const isDayInDateMatched = () => {
      if (!employeeData.dayInDate) return false;
      const dayInDate = new Date(employeeData.dayInDate);

      // Deduct 5 hours and 30 minutes
      dayInDate.setHours(dayInDate.getHours() - 5);
      dayInDate.setMinutes(dayInDate.getMinutes() - 30);
      const currentDate = new Date();
      
      return (
        dayInDate.getFullYear() === currentDate.getFullYear() &&
        dayInDate.getMonth() === currentDate.getMonth() &&
        dayInDate.getDate() === currentDate.getDate()
      );
    };

  return (
    <>
      <div className="top-navbar">
        <div className="top-navbar-content">
          <div className="toggle-btn" onClick={toggleNavbar}>
            <FontAwesomeIcon icon={faBars} />
          </div>
          <span className="admin-welcome">
            {employeeData.FirstName} {employeeData.LastName}
          </span>
          <div>
            <Link to="teammemberprofile" className="logout-btn me-2" onClick={() => handleLinkClick('')}>
              <FontAwesomeIcon icon={faUser} />
            </Link>
            <button onClick={handleLogout} className="logout-btn">
              <FontAwesomeIcon icon={faSignOutAlt} />
            </button>
          </div>
        </div>
      </div>

      <div className="admin-dashboard">
        <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
          <div className="logo-section">
            <div className="toggle-btn" onClick={toggleNavbar}>
              {isCollapsed ? '>' : '<'}
            </div>
          </div>
          <ul className="nav flex-column">
            <Link to="employeeHomePage" className="colourlink">
              <li className={`nav-item ${activeLink === 'Dashboard' ? 'active' : ''}`} onClick={() => handleLinkClick('Dashboard')}>
                <FontAwesomeIcon icon={faHouse} />
                {!isCollapsed && <span>Dashboard</span>}
              </li>
            </Link>

            <li className="nav-heading" onClick={() => toggleNavHeading('ledger')}>
  {toggleStates.ledger ? ( // Change the icon based on toggle state
    <>
      <FontAwesomeIcon icon={faCaretDown} />
      {!isCollapsed && ' Ledger'}
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faCaretRight} />
      {!isCollapsed && ' Ledger'}
    </>
  )}
</li>

{toggleStates.ledger && (
  <>
    <Link to="GSTRecon" className="colourlink">
      <li
        className={`nav-item ${activeLink === 'GST Recon' ? 'active' : ''}`}
        onClick={() => handleLinkClick('GST Recon')}
      >
              <FontAwesomeIcon icon={faBarsProgress} />
              {!isCollapsed && <span>GST Recon</span>}
            </li>
            </Link>
            <>
            {isMGEmployee && <>
            <Link to="PendingBilling" className="colourlink">
      <li
        className={`nav-item ${activeLink === 'Billing' ? 'active' : ''}`}
        onClick={() => handleLinkClick('Billing')}
      >
              <FontAwesomeIcon icon={faBarsProgress} />
              {!isCollapsed && <span>Billing</span>}
            </li>
            </Link>
            <Link to="PendingChallan" className="colourlink">
      <li
        className={`nav-item ${activeLink === 'Challan' ? 'active' : ''}`}
        onClick={() => handleLinkClick('Challan')}
      >
              <FontAwesomeIcon icon={faBarsProgress} />
              {!isCollapsed && <span>Challan</span>}
            </li>
            </Link>
            </>}
            </>
            {/* <Link to="EcomRecon" className="colourlink">
      <li
        className={`nav-item ${activeLink === 'Ecom Recon' ? 'active' : ''}`}
        onClick={() => handleLinkClick('Ecom Recon')}
      >
              <FontAwesomeIcon icon={faBarsProgress} />
              {!isCollapsed && <span>Ecom Recon</span>}
            </li>
            </Link> */}

            {/* <Link to="AddLedger" className="colourlink">
      <li
        className={`nav-item ${activeLink === 'Add Ledger' ? 'active' : ''}`}
        onClick={() => handleLinkClick('Add Ledger')}
      >
              <FontAwesomeIcon icon={faBarsProgress} />
              {!isCollapsed && <span>Add Ledger</span>}
            </li>
            </Link> */}
    <Link to="paymentoverdue" className="colourlink">
      <li
        className={`nav-item ${activeLink === 'Payment Overdue' ? 'active' : ''}`}
        onClick={() => handleLinkClick('Payment Overdue')}
      >
              <FontAwesomeIcon icon={faBarsProgress} />
              {!isCollapsed && <span>Payment Overdue</span>}
            </li>
            </Link>
      <Link to="exceptionalClientReport" className="colourlink">
        <li
          className={`nav-item ${activeLink === 'Exceptional Client Report' ? 'active' : ''}`}
          onClick={() => handleLinkClick('Exceptional Client Report')}
          >
              <FontAwesomeIcon icon={faBarsProgress} />
              {!isCollapsed && <span>Exceptional Client Report</span>}
        </li>
      </Link>
            {/* <Link to="GenerateRequest" className="colourlink">
      <li
        className={`nav-item ${activeLink === 'Generate Request' ? 'active' : ''}`}
        onClick={() => handleLinkClick('Generate Request')}
      >
              <FontAwesomeIcon icon={faBarsProgress} />
              {!isCollapsed && <span>Generate Request</span>}
            </li>
            </Link> */}
  </>
)}
            <li className="nav-heading" onClick={() => toggleNavHeading('functions')}>
  {toggleStates.functions ? ( // Change the icon based on toggle state
    <>
      <FontAwesomeIcon icon={faCaretDown} />
      {!isCollapsed && ' Functions'}
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faCaretRight} />
      {!isCollapsed && ' Functions'}
    </>
  )}
</li>
{toggleStates.functions && (
  <>
            <Link to="employeedayin" className="colourlink">
              <li className={`nav-item ${activeLink === 'Team Member Day In' ? 'active' : ''}`} onClick={() => handleLinkClick('Team Member Day In')}>
                <FontAwesomeIcon icon={faBusinessTime} />
                {!isCollapsed && <span>Day In</span>}
              </li>
            </Link>

            <Link to="dailyworking" className="colourlink">
              <li className={`nav-item ${activeLink === 'Daily Working' ? 'active' : ''}`} onClick={() => handleLinkClick('Daily Working')}>
                <FontAwesomeIcon icon={faBriefcase} />
                {!isCollapsed && <span>Daily Tasks</span>}
              </li>
            </Link>
            <Link to="employeedayout" className="colourlink">
              <li className={`nav-item ${activeLink === 'Team Member Day Out' ? 'active' : ''}`} onClick={() => handleLinkClick('Team Member Day Out')}>
                <FontAwesomeIcon icon={faBusinessTime} />
                {!isCollapsed && <span>Day Out</span>}
              </li>
            </Link>
            {employeeData.hoursType === 'fixed' ? (
            <Link to="fixedDailySummary" className='colourlink'>
              <li className={`nav-item ${activeLink === 'Daily Summary' ? 'active' : ''}`} onClick={() => handleLinkClick('Daily Summary')}>
                <FontAwesomeIcon icon={faCalendarDays} />
                {!isCollapsed && <span>Daily Summary</span>}
              </li>
            </Link>
            ) : (
              <Link to="floatingDailySummary" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Daily Summary' ? 'active' : ''}`} onClick={() => handleLinkClick('Daily Summary')}>
              <FontAwesomeIcon icon={faCalendarDays} />
              {!isCollapsed && <span>Daily Summary</span>}
            </li>
            </Link>
            )}
            
            <Link to="transporter" className='colourlink'>
            <li className={`nav-item ${activeLink === "Transporter" ? 'active' : ''}`} onClick={() => handleLinkClick("Transporter")}>
              <FontAwesomeIcon icon={faUserPlus} />
              {!isCollapsed && <span>Transporter List</span>}
            </li>
            </Link>
            <Link to="clientSOP" className='colourlink'>
            <li className={`nav-item ${activeLink === "Client's SOP" ? 'active' : ''}`} onClick={() => handleLinkClick("Client's SOP")}>
              <FontAwesomeIcon icon={faUserPlus} />
              {!isCollapsed && <span>Client's SOP</span>}
            </li>
            </Link>
            </>
            )}



            <li className="nav-heading" onClick={() => toggleNavHeading('assignments')}>
  {toggleStates.assignments ? ( // Change the icon based on toggle state
    <>
      <FontAwesomeIcon icon={faCaretDown} />
      {!isCollapsed && ' Assignments'}
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faCaretRight} />
      {!isCollapsed && ' Assignments'}
    </>
  )}
</li>
{toggleStates.assignments && (
  <>
            <Link to="assigntasks" className="colourlink">
              <li className={`nav-item ${activeLink === 'Assign Tasks' ? 'active' : ''}`} onClick={() => handleLinkClick('Assign Tasks')}>
                <FontAwesomeIcon icon={faUserPlus} />
                {!isCollapsed && <span>Assign Tasks</span>}
              </li>
            </Link>
            <Link to="employeePendingTasks" className='colourlink'>
            <li className={`nav-item ${activeLink === 'All Pending Tasks' ? 'active' : ''}`} onClick={() => handleLinkClick('All Pending Tasks')}>
            <FontAwesomeIcon icon={faClockRotateLeft} />
              {!isCollapsed && <span>All Pending Tasks</span>}
            </li>
            </Link>
            <Link to="myTasks" className='colourlink'>
            <li className={`nav-item ${activeLink === 'My Tasks' ? 'active' : ''}`} onClick={() => handleLinkClick('My Tasks')}>
            <FontAwesomeIcon icon={faClockRotateLeft} />
              {!isCollapsed && <span>My Tasks</span>}
            </li>
            </Link>
            </>
)}

            {isDayInDateMatched() && (
              <>
            <li className="nav-heading" onClick={() => toggleNavHeading('credentials')}>
  {toggleStates.credentials ? ( // Change the icon based on toggle state
    <>
      <FontAwesomeIcon icon={faCaretDown} />
      {!isCollapsed && ' Compliance'}
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faCaretRight} />
      {!isCollapsed && ' Compliance'}
    </>
  )}
</li>
{toggleStates.credentials && (
  <>
            <Link to="gstCredentialsMQ" className="colourlink">
              <li className={`nav-item ${activeLink === 'GST Credentials-M&Q' ? 'active' : ''}`} onClick={() => handleLinkClick('GST Credentials-M&Q')}>
                <FontAwesomeIcon icon={faList} />
                {!isCollapsed && <span>GST Compliance-M&Q</span>}
              </li>
            </Link>

            <Link to="gstCredentialsHY" className="colourlink">
              <li className={`nav-item ${activeLink === 'GST Credentials-H&Y' ? 'active' : ''}`} onClick={() => handleLinkClick('GST Credentials-H&Y')}>
                <FontAwesomeIcon icon={faList} />
                {!isCollapsed && <span>GST Compliance-H&Y</span>}
              </li>
            </Link>

            <Link to="EwayCredentials" className="colourlink">
              <li className={`nav-item ${activeLink === 'E-Way Credentials' ? 'active' : ''}`} onClick={() => handleLinkClick('E-Way Credentials')}>
                <FontAwesomeIcon icon={faList} />
                {!isCollapsed && <span>E-Way Compliance</span>}
              </li>
            </Link>

            <Link to="InvoiceCredentials" className="colourlink">
              <li className={`nav-item ${activeLink === 'Invoice Credentials' ? 'active' : ''}`} onClick={() => handleLinkClick('Invoice Credentials')}>
                <FontAwesomeIcon icon={faList} />
                {!isCollapsed && <span>Invoice Compliance</span>}
              </li>
            </Link>

            <Link to="tdsCredentials" className="colourlink">
              <li className={`nav-item ${activeLink === 'TDS Credentials' ? 'active' : ''}`} onClick={() => handleLinkClick('TDS Credentials')}>
                <FontAwesomeIcon icon={faList} />
                {!isCollapsed && <span>TDS Compliance</span>}
              </li>
            </Link>

            <Link to="TcsCredentials" className="colourlink">
              <li className={`nav-item ${activeLink === 'TCS Credentials' ? 'active' : ''}`} onClick={() => handleLinkClick('TCS Credentials')}>
                <FontAwesomeIcon icon={faList} />
                {!isCollapsed && <span>TCS Compliance</span>}
              </li>
            </Link>

            <Link to="taxCredentials" className="colourlink">
              <li className={`nav-item ${activeLink === 'TAX Credentials' ? 'active' : ''}`} onClick={() => handleLinkClick('TAX Credentials')}>
                <FontAwesomeIcon icon={faList} />
                {!isCollapsed && <span>TAX Compliance</span>}
              </li>
            </Link>

            <Link to="ecomCredentials" className="colourlink">
              <li className={`nav-item ${activeLink === 'E-Commerce List' ? 'active' : ''}`} onClick={() => handleLinkClick('E-Commerce List')}>
                <FontAwesomeIcon icon={faList} />
                {!isCollapsed && <span>E-Commerce List</span>}
              </li>
            </Link>

            </>
            )}
            </>
            )}
            {isMGEmployee && <>
            <li className="nav-heading" onClick={() => toggleNavHeading('masterList')}>
  {toggleStates.masterList ? ( // Change the icon based on toggle state
    <>
      <FontAwesomeIcon icon={faCaretDown} />
      {!isCollapsed && ' Master List'}
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faCaretRight} />
      {!isCollapsed && ' Master List'}
    </>
  )}
</li>
{toggleStates.masterList && (
  <>
  <Link to="TDSMasterList" className='colourlink'>
            <li className={`nav-item ${activeLink === 'TDS Master List' ? 'active' : ''}`} onClick={() => handleLinkClick('TDS Master List')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>TDS Master List</span>}
            </li>
            </Link>
            <Link to="TCSMasterList" className='colourlink'>
            <li className={`nav-item ${activeLink === 'TCS Master List' ? 'active' : ''}`} onClick={() => handleLinkClick('TCS Master List')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>TCS Master List</span>}
            </li>
            </Link>

            </>
)}
            </>}
            <li className="nav-heading" onClick={() => toggleNavHeading('requests')}>
  {toggleStates.requests ? ( // Change the icon based on toggle state
    <>
      <FontAwesomeIcon icon={faCaretDown} />
      {!isCollapsed && ' Requests'}
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faCaretRight} />
      {!isCollapsed && ' Requests'}
    </>
  )}
</li>
{toggleStates.requests && (
  <>
            <Link to="leaveapplicationform" className="colourlink">
              <li className={`nav-item ${activeLink === 'Leave Application Form' ? 'active' : ''}`} onClick={() => handleLinkClick('Leave Application Form')}>
                <FontAwesomeIcon icon={faUserPlus} />
                {!isCollapsed && <span>Leave Application Form</span>}
              </li>
            </Link>
            <Link to="lateentryearlyexit" className="colourlink">
              <li className={`nav-item ${activeLink === 'Late Entry / Early Exit Form' ? 'active' : ''}`} onClick={() => handleLinkClick('Late Entry / Early Exit Form')}>
                <FontAwesomeIcon icon={faUserPlus} />
                {!isCollapsed && <span>Late Entry / Early Exit Form</span>}
              </li>
            </Link>
            <Link to="leaverequests" className="colourlink">
              <li className={`nav-item ${activeLink === 'Leave Requests' ? 'active' : ''}`} onClick={() => handleLinkClick('Leave Requests')}>
                <FontAwesomeIcon icon={faList} />
                {!isCollapsed && <span>My Requests</span>}
              </li>
            </Link>
            </>
)}

            <li className="nav-heading" onClick={() => toggleNavHeading('userTeamReports')}>
              {toggleStates.userTeamReports ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' User Reports'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' User Reports'}
                </>
              )}
            </li>
            {toggleStates.userTeamReports && (
              <>
                        <Link to="myCompletedTask" className="colourlink">
                          <li className={`nav-item ${activeLink === 'My Completed Tasks' ? 'active' : ''}`} onClick={() => handleLinkClick('My Completed Tasks')}>
                            <FontAwesomeIcon icon={faListCheck} />
                            {!isCollapsed && <span>Daily Task Report</span>}
                          </li>
                        </Link>
                        
                        <Link to="mytimesheets" className="colourlink">
                          <li className={`nav-item ${activeLink === 'My Timesheets' ? 'active' : ''}`} onClick={() => handleLinkClick('My Timesheets')}>
                            <FontAwesomeIcon icon={faList} />
                            {!isCollapsed && <span>Daily Time Summary</span>}
                          </li>
                        </Link>
                        <Link to="memberproductivityAnalysis" className='colourlink'>
                        <li className={`nav-item ${activeLink === "Member Productivity Analysis" ? 'active' : ''}`} onClick={() => handleLinkClick("Member Productivity Analysis")}>
                        <FontAwesomeIcon icon={faChartPie} />
                          {!isCollapsed && <span>Productivity Analysis</span>}
                        </li>
                        </Link>
                        <Link to="memberdayinoutreport" className='colourlink'>
                        <li className={`nav-item ${activeLink === 'Member Day In / Day Out' ? 'active' : ''}`} onClick={() => handleLinkClick('Member Day In / Day Out')}>
                        <FontAwesomeIcon icon={faList} />
                          {!isCollapsed && <span>Day In / Day Out Report</span>}
                        </li>
                        </Link>
                        <Link to="memberDayStatusReport" className='colourlink'>
                        <li className={`nav-item ${activeLink === 'Member Day Status Report' ? 'active' : ''}`} onClick={() => handleLinkClick('Member Day Status Report')}>
                        <FontAwesomeIcon icon={faList} />
                          {!isCollapsed && <span>Day Status Report</span>}
                        </li>
                        </Link>
                        </>
            )}
                        <li className="nav-heading" onClick={() => toggleNavHeading('userClientReports')}>
              {toggleStates.userClientReports ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' Client Reports'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' Client Reports'}
                </>
              )}
            </li>
            {toggleStates.userClientReports && (
              <>
                        <Link to="vDateMatrix" className='colourlink'>
                        <li className={`nav-item ${activeLink === "V Date Matrix" ? 'active' : ''}`} onClick={() => handleLinkClick("V Date Matrix")}>
                          <FontAwesomeIcon icon={faListCheck} />
                          {!isCollapsed && <span>Client Overview</span>}
                        </li>
                        </Link>
                        <Link to="memberClientCompletedTask" className='colourlink'>
                        <li className={`nav-item ${activeLink === "Member Client's Completed Tasks" ? 'active' : ''}`} onClick={() => handleLinkClick("Member Client's Completed Tasks")}>
                          <FontAwesomeIcon icon={faListCheck} />
                          {!isCollapsed && <span>Client - DTR</span>}
                        </li>
                        </Link>
                        <Link to="membervisitreport" className='colourlink'>
                        <li className={`nav-item ${activeLink === "Member Visit Report" ? 'active' : ''}`} onClick={() => handleLinkClick("Member Visit Report")}>
                          <FontAwesomeIcon icon={faListCheck} />
                          {!isCollapsed && <span>Visit Report</span>}
                        </li>
                        </Link>
                        <Link to="memberstdTimeVarianceReport" className='colourlink'>
                        <li className={`nav-item ${activeLink === "Member Standard Time Variance" ? 'active' : ''}`} onClick={() => handleLinkClick("Member Standard Time Variance")}>
                          <FontAwesomeIcon icon={faListCheck} />
                          {!isCollapsed && <span>Standard Time Variance</span>}
                        </li>
                        </Link>
                        <Link to="memberClientTimeReport" className='colourlink'>
                        <li className={`nav-item ${activeLink === "Member Client Time Report" ? 'active' : ''}`} onClick={() => handleLinkClick("Member Client Time Report")}>
                          <FontAwesomeIcon icon={faListCheck} />
                          {!isCollapsed && <span>Time Absorption</span>}
                        </li>
                        </Link>
                        </>
            )}
            {isMGEmployee && <>

           <li className="nav-heading" onClick={() => toggleNavHeading('approvals')}>
  {toggleStates.approvals ? ( // Change the icon based on toggle state
    <>
      <FontAwesomeIcon icon={faCaretDown} />
      {!isCollapsed && ' Approvals'}
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faCaretRight} />
      {!isCollapsed && ' Approvals'}
    </>
  )}
</li>
{toggleStates.approvals && (
  <>
            <Link to="employeeleavereq" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Requests' ? 'active' : ''}`} onClick={() => handleLinkClick('Requests')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Leave / Late-Early Requests</span>}
            </li>
            </Link>
            <Link to="adminaccessreq" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Access Requests' ? 'active' : ''}`} onClick={() => handleLinkClick('Access Requests')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Access Requests</span>}
            </li>
            </Link>
            
            </>
)}
</>
            }
            {(isMGEmployee || isTM003) && <>
            <li className="nav-heading" onClick={() => toggleNavHeading('mgUserReports')}>
              {toggleStates.mgUserReports ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' MG User Reports'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' MG User Reports'}
                </>
              )}
            </li>
            {toggleStates.mgUserReports && (
              <>
            <Link to="completedTaskForAdmin" className='colourlink'>
            <li className={`nav-item ${activeLink === "Team Member's Completed Tasks" ? 'active' : ''}`} onClick={() => handleLinkClick("Team Member's Completed Tasks")}>
              <FontAwesomeIcon icon={faListCheck} />
              {!isCollapsed && <span>TM - DTR</span>}
            </li>
            </Link>
            <Link to="timesheetListForAdmin" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Team Member Timesheets' ? 'active' : ''}`} onClick={() => handleLinkClick('Team Member Timesheets')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Daily Summary Approval</span>}
            </li>
            </Link>
            <Link to="productivityAnalysis" className='colourlink'>
            <li className={`nav-item ${activeLink === "Productivity Analysis" ? 'active' : ''}`} onClick={() => handleLinkClick("Productivity Analysis")}>
            <FontAwesomeIcon icon={faChartPie} />
              {!isCollapsed && <span>Productivity Analysis</span>}
            </li>
            </Link>
            <Link to="dayinoutreport" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Day In / Day Out' ? 'active' : ''}`} onClick={() => handleLinkClick('Day In / Day Out')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Day In / Day Out Report</span>}
            </li>
            </Link>
            <Link to="dayStatusReport" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Day Status Report' ? 'active' : ''}`} onClick={() => handleLinkClick('Day Status Report')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Day Status Report</span>}
            </li>
            </Link>
            <Link to="surplusDeficiencyReport" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Surplus/Deficiency Report' ? 'active' : ''}`} onClick={() => handleLinkClick('Surplus/Deficiency Report')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Surplus/Deficiency Report</span>}
            </li>
            </Link>
            <Link to="showassignclient" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Assign Client List' ? 'active' : ''}`} onClick={() => handleLinkClick('Assign Client List')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Assigned Team List</span>}
            </li>
            </Link>
            </>
            )}
            <li className="nav-heading" onClick={() => toggleNavHeading('mgClientReports')}>
              {toggleStates.mgClientReports ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' MG Client Reports'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' MG Client Reports'}
                </>
              )}
            </li>
            {toggleStates.mgClientReports && (
              <>
            <Link to="clientCompletedTask" className='colourlink'>
            <li className={`nav-item ${activeLink === "Client's Completed Tasks" ? 'active' : ''}`} onClick={() => handleLinkClick("Client's Completed Tasks")}>
              <FontAwesomeIcon icon={faListCheck} />
              {!isCollapsed && <span>Client - DTR</span>}
            </li>
            </Link>
            <Link to="visitreport" className='colourlink'>
            <li className={`nav-item ${activeLink === "Visit Report" ? 'active' : ''}`} onClick={() => handleLinkClick("Visit Report")}>
              <FontAwesomeIcon icon={faListCheck} />
              {!isCollapsed && <span>Visit Report</span>}
            </li>
            </Link>
            <Link to="stdTimeVarianceReport" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Standard Time Variance Report' ? 'active' : ''}`} onClick={() => handleLinkClick('Standard Time Variance Report')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Standard Time Variance</span>}
            </li>
            </Link>
        
            </>
            )}
            <li className="nav-heading" onClick={() => toggleNavHeading('mgGlobalReports')}>
              {toggleStates.mgGlobalReports ? ( // Change the icon based on toggle state
                <>
                  <FontAwesomeIcon icon={faCaretDown} />
                  {!isCollapsed && ' MG Global Reports'}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCaretRight} />
                  {!isCollapsed && ' MG Global Reports'}
                </>
              )}
            </li>
            {toggleStates.mgGlobalReports && (
              <>
            <Link to="teamDailyTaskReport" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Team Daily Task Report' ? 'active' : ''}`} onClick={() => handleLinkClick('Team Daily Task Report')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Team Group Summary</span>}
            </li>
            </Link>
            <Link to="taskOverview" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Task Overview' ? 'active' : ''}`} onClick={() => handleLinkClick('Task Overview')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Task Overview</span>}
            </li>
            </Link>
            <Link to="clientReport" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Date Matrix' ? 'active' : ''}`} onClick={() => handleLinkClick('Date Matrix')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Date Matrix</span>}
            </li>
            </Link>
            <Link to="clientTimeReport" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Client Time Report' ? 'active' : ''}`} onClick={() => handleLinkClick('Client Time Report')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Time Absorption</span>}
            </li>
            </Link>
            
            </>
            )}
            </>
            }
            {isMGEmployee && <>


<li className="nav-heading" onClick={() => toggleNavHeading('clientFunctions')}>
  {toggleStates.clientFunctions ? ( // Change the icon based on toggle state
    <>
      <FontAwesomeIcon icon={faCaretDown} />
      {!isCollapsed && ' Client Functions'}
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faCaretRight} />
      {!isCollapsed && ' Client Functions'}
    </>
  )}
</li>    

            {toggleStates.clientFunctions && (
              <>

            <Link to="addclient" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Add Client' ? 'active' : ''}`} onClick={() => handleLinkClick('Add Client')}>
              <FontAwesomeIcon icon={faUserPlus} />
              {!isCollapsed && <span>Add Client</span>}
            </li>
            </Link>
            <Link to="clientlist" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Client List' ? 'active' : ''}`} onClick={() => handleLinkClick('Client List')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Edit Client</span>}
            </li>
            </Link>
            

            </>
            )}
            

<li className="nav-heading" onClick={() => toggleNavHeading('teamMemberFunctions')}>
  {toggleStates.teamMemberFunctions ? ( // Change the icon based on toggle state
    <>
      <FontAwesomeIcon icon={faCaretDown} />
      {!isCollapsed && ' TM Functions'}
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faCaretRight} />
      {!isCollapsed && ' TM Functions'}
    </>
  )}
</li>
            {toggleStates.teamMemberFunctions && (
              <>
            <Link to="assignclient" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Assign Client' ? 'active' : ''}`} onClick={() => handleLinkClick('Assign Client')}>
              <FontAwesomeIcon icon={faUserPlus} />
              {!isCollapsed && <span>Assign Team</span>}
            </li>
            </Link>
            <Link to="addteammember" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Add Team Member' ? 'active' : ''}`} onClick={() => handleLinkClick('Add Team Member')}>
              <FontAwesomeIcon icon={faUserPlus} />
              {!isCollapsed && <span>Add Team Member</span>}
            </li>
            </Link>
            <Link to="userlist" className='colourlink'>
            <li className={`nav-item ${activeLink === 'User List' ? 'active' : ''}`} onClick={() => handleLinkClick('User List')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Team Member List</span>}
            </li>
            </Link>   
            </>
            )}
            <li className="nav-heading" onClick={() => toggleNavHeading('taskManager')}>
  {toggleStates.taskManager ? ( // Change the icon based on toggle state
    <>
      <FontAwesomeIcon icon={faCaretDown} />
      {!isCollapsed && ' Task Manager'}
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faCaretRight} />
      {!isCollapsed && ' Task Manager'}
    </>
  )}
</li>
{toggleStates.taskManager && (
              <>
            <Link to="taskManager" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Task Manager' ? 'active' : ''}`} onClick={() => handleLinkClick('Task Manager')}>
              <FontAwesomeIcon icon={faBarsProgress} />
              {!isCollapsed && <span>Add / Edit Tasks</span>}
            </li>
            </Link>
            </>
            )}    
            </>
            }
            
          </ul>
        </div>

        <div className='content-area'>
          <Routes>
            <Route path="/employeeHomePage" element={<EmployeeHomePage />} />
            <Route path="/dailyworking" element={<DailyWorking />} />
            <Route path="/assignTasks" element={<AssignTask />} />
            <Route path="/leaveapplicationform" element={<LeaveApplicationForm />} />
            <Route path="/teammemberprofile" element={<TeamMemberProfile />} />
            <Route path="/leaverequests" element={<ShowRequests />} />
            <Route path="/lateentryearlyexit" element={<LateEntryEarlyExitForm />} />
            <Route path="/showteamtasks" element={<ShowEmployeeTasks />} />
            <Route path="/employeedayinout" element={<DayInOut />} />
            <Route path="/clientvisittracker" element={<ClientVisitTracker />} />
            <Route path="/timesheet" element={<Timesheet />} />
            <Route path="/myCompletedTask" element={<MyCompletedTask />} />
            <Route path="/usermyclientdetails" element={<UserMyClients />} />
            <Route path="/mytimesheets" element={<MyTimesheet />} />
            <Route path="/floatingtimesheet" element={<FloatingTimesheet />} />
            <Route path="/timesheetPreviewFixed" element={<TimesheetPreviewFixed />} />
            <Route path="/timesheetPreviewFloating" element={<TimesheetPreviewFloating />} />
            <Route path="/addteammember" element={<AddTeamMember />} />
            <Route path="/userlist" element={<UserList />} />
            <Route path="/addclient" element={<AddClient />} />
            <Route path="/clientlist" element={<ClientList />} />
            <Route path="/assignclient" element={<EmployeeClientSelector />} />
            <Route path="/showassignclient" element={<Showassignclient />} />
            <Route path="/employeeleavereq" element={<AdminRequests/>}/>
            <Route path="/adminaccessreq" element={<AdminAccessRequests/>}/>
            <Route path="/completedTaskForAdmin" element={<CompletedTaskForAdmin/>}/>
            <Route path="/edit-user/:id" element={<EditTeamMember />} />
            <Route path="/edit-client/:clientId" element={<EditClient />} />
            <Route path='/timesheetListForAdmin' element={<TimesheetListForAdmin/>}/>
            <Route path='/fixedTimesheetApproval' element={<FixedTimesheetApproval/>}/>
            <Route path='/floatingTimesheetApproval' element={<FloatingTimesheetApproval/>}/>
            <Route path='/clientCompletedTask' element={<ClientCompletedTaskForAdmin/>}/>
            <Route path='/dayinoutreport' element={<DayInOutReport/>}/>
            <Route path='/visitreport' element={<VisitReport/>}/>
            <Route path='/PendingTaskDailyWorking' element={<PendingTaskDailyWorking/>}/>
            <Route path='/employeePendingTasks' element={<ShowEmployeeTasks/>}/>
            <Route path='/myTasks' element={<MyTasks/>}/>
            <Route path='/productivityAnalysis' element={<ProductivityAnalysis/>}/>
            <Route path='/clientReport' element={<ClientReport/>}/>
            <Route path='/gstCredentialsMQ' element={<GSTCredentials/>}/>
            <Route path='/taxCredentials' element={<TaxCredentials/>}/>
            <Route path='/tdsCredentials' element={<TdsTcsCredentials/>}/>
            <Route path='/TcsCredentials' element={<TcsCredentials/>}/>
            <Route path='/EwayCredentials' element={<EWayInvoiceCredentials/>}/>
            <Route path='/InvoiceCredentials' element={<InvoiceCredentials/>}/>
            <Route path='/assignTaskByReports' element={<AssignTasksByReport/>}/>
            <Route path='/taskManager' element={<TaskManager/>}/>
            <Route path='/membervisitreport' element={<MemberVisitReport/>}/>
            <Route path='/memberproductivityAnalysis' element={<MemberProductivityAnalysis/>}/>
            <Route path='/memberClientCompletedTask' element={<MemberClientCompletedTask/>}/>
            <Route path='/editDailyWorking' element={<EditDailyWorking/>}/>
            <Route path='/vDateMatrix' element={<VDateMatrix/>}/>
            <Route path='/floatingDailySummary' element={<FloatingDailySummary/>}/>
            <Route path='/fixedDailySummary' element={<FixedDailySummary/>}/>
            <Route path="/employeedayin" element={<DayIn />} />
            <Route path="/employeedayout" element={<DayOut />} />
            <Route path="/teamDailyTaskReport" element={<TeamDailyTaskReport />} />
            <Route path="/clientTimeReport" element={<ClientTimeReport />} />
            <Route path='/paymentoverdue' element={<PaymentOverdueForm/>}/>
            <Route path='/memberClientTimeReport' element={<MemberClientTimeReport/>}/>
            <Route path='/memberdayinoutreport' element={<MemberDayInOutReport/>}/>
            <Route path='/stdTimeVarianceReport' element={<StandardTimeVarianceReport/>}/>
            <Route path='/memberstdTimeVarianceReport' element={<MemberStandardTimeVariance/>}/>
            <Route path='/transporter' element={<TransporterDetails/>}/>
            <Route path='/clientSOP' element={<SOPForm/>}/>
            <Route path='/taskOverview' element={<TaskOverview/>}/>
            <Route path='/ecomCredentials' element={<ECOMCredentials/>}/>
            <Route path='/gstCredentialsHY' element={<GSTCredentialsHY/>}/>
            <Route path='/AddLedger' element={<AddLedger/>}/>
            <Route path='/PendingBilling' element={<Billing/>}/>
            <Route path='/PendingChallan' element={<Challan/>}/>
            <Route path='/GSTRecon' element={<GSTReconPage/>}/>
            <Route path='/dayStatusReport' element={<DayStatusReport/>}/>
            <Route path='/memberDayStatusReport' element={<MemberDayStatusReport/>}/>
            <Route path='/TCSMasterList' element={<TCSMasterList/>}/>
            <Route path='/TDSMasterList' element={<TDSMasterList/>}/>
            <Route path='/exceptionalClientReport' element={<ExceptionalClientReport/>}/>
            <Route path='/surplusDeficiencyReport' element={<SurplusDeficiencyReport/>}/>
          </Routes>
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
