import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { useTable, usePagination, useGlobalFilter } from 'react-table';

const DayOut = () => {
  const [dayOut, setDayOut] = useState(null);
  const [locationOut, setLocationOut] = useState('');
  const [otherLocation, setOtherLocation] = useState('');
  const [employeeData, setEmployeeData] = useState(null);
  const [isOnTime, setIsOnTime] = useState(null);
  const [earlyMinutes, setEarlyMinutes] = useState(null);
  const [requestInfo, setRequestInfo] = useState(null);
  const [enteredTime, setEnteredTime] = useState('');
  const [latestDate, setLatestDate] = useState('');
  const [outBefireIn, setOutBeforeIn] = useState(false);
  const [pendingTasks, setPendingTasks] = useState([]);

  const email = sessionStorage.getItem('email');

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email },
        });
        const employeeData = response.data[0];
        setEmployeeData(employeeData);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    const fetchLatestDate = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getLatestIncompleteDay', {
          params: { email },
        });
    
        // Check if the response contains a date
        if (response.status === 200 && response.data && response.data.date) {
          // Ensure the date is in 'YYYY-MM-DD' format for consistency
          const formattedDate = moment(response.data.date).format('YYYY-MM-DD');
          setLatestDate(formattedDate);
        } else {
          setLatestDate(null); // Optionally, clear the date if there's no incomplete day
        }
      } catch (error) {
        // Handle errors here
        console.error('Error fetching latest date:', error);
      }
    };
    

    if (email) {
      fetchEmployeeData();
      fetchLatestDate();
    }
  }, [email]);
  const empid = employeeData?.empid;
  const fetchemployeetasks = async () => {
    try {
      const pendingResponse = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/showSpecificEmployeeTasks', { empid });

      setPendingTasks(groupTasks(pendingResponse.data));
    } catch (error) {
      Swal.fire('Error', 'There was an error fetching tasks!', 'error');
    }
  };

  useEffect(() => {
    if (empid) {
      fetchemployeetasks();
    }
  }, [empid]);

  const checkDateExists = async (date) => {
    try {
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/checkDateInDailyWorking', {
        params: { date, email },
      });
      return response.data.exists; // Return true if the date exists
    } catch (error) {
      console.error('Error checking date existence:', error);
      return false; // Assume the date does not exist if there's an error
    }
  };

  const handleDayOut = async () => {
    // Ensure latestDate is available
    if (!latestDate) {
      setRequestInfo('Latest date not available.');
      return;
    }

    // Check if the date exists
  const dateExists = await checkDateExists(latestDate);
  
  if (!dateExists) {
    setRequestInfo('Please Submit Daily Task for this date.');
    return;
  }

    // Combine latestDate and enteredTime with an explicit format
    const dateTimeString = `${latestDate} ${enteredTime}`;
    const formatString = 'YYYY-MM-DD HH:mm';

    const now = moment.tz(dateTimeString, formatString, 'Asia/Kolkata');

    if (!now.isValid()) {
      setRequestInfo('Invalid time format. Please use HH:mm.');
      return;
    }

    setDayOut(now.toDate());

    getLocation(async (location) => {
      const finalLocation = locationOut === 'Any Other' ? otherLocation : locationOut;
      setLocationOut(finalLocation);
      await processDayOut(now, location.latitude, location.longitude, finalLocation);
    });
  };

  const getLocation = (callback) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          callback(location);
        },
        (error) => {
          console.error('Error getting location:', error);
          callback({ latitude: null, longitude: null });
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      callback({ latitude: null, longitude: null });
    }
  };

  const processDayOut = async (outtime, latitude, longitude, place) => {
    if (!employeeData || !latestDate) return;

    const { empid, FirstName, LastName, hoursType, ScheduleOut } = employeeData;
    const fullname = `${FirstName} ${LastName}`;
    const outtimeIST = moment(outtime).tz('Asia/Kolkata');
    const outtimeTimeStr = outtimeIST.format('HH:mm:ss');
    const formattedOuttime = `${latestDate}T${outtimeTimeStr}`;

    let exitontime = 0;
    let finalEarly = 0;
    let diffMinutes = 0;
    let earlyinformed = null;

    try {
      if (hoursType === 'fixed' && ScheduleOut) {
        // Ensure ScheduleOut is in 'YYYY-MM-DDTHH:mm:ss' format
        const scheduleOutTime = ScheduleOut.split('T')[1].split('.')[0]; // Extract time part
        const outtimeTime = outtimeTimeStr;

        const scheduleTime = moment(`1970-01-01T${scheduleOutTime}`, 'YYYY-MM-DDTHH:mm:ss');
        const outtimeTimeObj = moment(`1970-01-01T${outtimeTime}`, 'YYYY-MM-DDTHH:mm:ss');

        diffMinutes = outtimeTimeObj.diff(scheduleTime, 'minutes');

        if (diffMinutes < 0) {
          setIsOnTime(false);
          setEarlyMinutes(Math.abs(diffMinutes));
          const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getlateearlyrequests', {
            params: { email, date: latestDate },
          });

          const earlyExitRequests = response.data.filter(req => req.type === 'Early Exit');    
          
          let difftime = 0;

          if (earlyExitRequests.length > 0) {
            const request = earlyExitRequests[0];
            
            if (request.type === 'Early Exit') {
              earlyinformed = 1;
              difftime = parseInt(request.difftime, 10);

              if (Math.abs(diffMinutes) <= difftime) {
                finalEarly = Math.abs(diffMinutes);
              } else {
                finalEarly = difftime + 3 * (Math.abs(diffMinutes) - difftime);
              }
              setRequestInfo('An Early Exit request has been fetched; the time will be adjusted accordingly.');
            } else {
              finalEarly = 3 * Math.abs(diffMinutes);
              earlyinformed = 0;
              setRequestInfo('No Pre Informed request found; hence, the time will be adjusted accordingly.');
            }
          } else {
            finalEarly = 3 * Math.abs(diffMinutes);
            earlyinformed = 0;
            setRequestInfo('No Pre Informed request found; hence, the time will be adjusted accordingly.');
          }
        } else {
          setIsOnTime(true);
          exitontime = 1;
        }
      } else {
        setIsOnTime(true);
        exitontime = 1;
      }

      await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/update-dayout', {
        empid,
        fullname,
        email,
        date: latestDate,  // Use the fetched date
        outtime: formattedOuttime,
        outlatitude: latitude,
        outlongitude: longitude,
        outplace: place,
        hoursType,
        earlyinformed,
        exitontime,
        ...(hoursType === 'fixed' ? {
          earlyby: Math.abs(diffMinutes),
          finalEarly
        } : {})
      });

      setRequestInfo('Day Out successfully recorded.');
      setOutBeforeIn(false)
    } catch (error) {
      console.error('Error processing Day Out:', error);
    
      // Check if the error message from the server indicates the outtime/intime issue
      const errorMessage = error.response?.data;
    
      if (errorMessage === 'Outtime cannot be earlier than intime') {
        setOutBeforeIn(true)
        Swal.fire({
          icon: 'error',
          title: 'Invalid Time',
          text: 'Outtime cannot be earlier than intime'
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error processing Day Out'
        });
      }
    }
  };
  const groupTasks = (tasks) => {
      console.log(tasks);
    
      const grouped = tasks.reduce((acc, task) => {
        const key = `${task.empid}-${task.clientid}-${task.mainTask}-${task.remarks || '-'}`;
        
        if (!acc[key]) {
          acc[key] = {
            ids: [task.ID], // Store the first task ID in an array
            empid: task.empid[0],
            FirstName: task.FirstName,
            LastName: task.LastName,
            clientid: task.clientid,
            TradeName: task.TradeName,
            mainTask: task.mainTask || '-',
            taskname: [task.taskname],
            AssignedBy: task.AssignedBy,
            deadline: task.deadline ? moment(task.deadline).format('DD-MM-YYYY') : '-',
            remarks: task.remarks || '-',
            completedOn : task.completedOn
          };
        } else {
          acc[key].taskname.push(task.taskname);
          acc[key].ids.push(task.ID); // Append additional task IDs to the array
        }
        
        return acc;
      }, {});
    
      return Object.values(grouped); // 🔥 Return as an array instead of an object
    };
    const columns = useMemo(
        () => [
          { Header: 'CC', accessor: 'clientid' },
          { Header: 'Trade Name', accessor: 'TradeName' },
          { Header: 'Main Task', accessor: 'mainTask' },
          { Header: 'Sub Task', accessor: (row) => row.taskname.join(', ') },
          { Header: 'Remarks', accessor: 'remarks' },
          { Header: 'Deadline', accessor: 'deadline' },
          { Header: 'Assigned By', accessor: 'AssignedBy' },
        ],
        []
      );
    
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      setGlobalFilter,
      canPreviousPage,
      canNextPage,
      pageOptions,
      nextPage,
      previousPage,
      state: { pageIndex, pageSize },
      setPageSize,
    } = useTable(
      {
        columns,
        data: pendingTasks, // ✅ Always an array
        initialState: { pageIndex: 0, pageSize: 5 },
      },
      useGlobalFilter,
      usePagination
    );

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center">
      <h2 className="mb-4">Day Out Tracker</h2>
      <div className="alert alert-danger" role="alert">
      <li className="small mb-0" style={{fontWeight:600}}> Please enter the time in 24 HR format or check the AM/PM before submitting.</li>
    </div>
      </div>
        {latestDate ? (<>
      <div className="form-group mt-2">
        <label><strong>Date:</strong></label>
        <input
          type="text"
          className="form-control"
          value={latestDate}
          disabled
        />
      </div>
      <div className="form-group mt-2">
        <label htmlFor="timeInput"><strong>Enter Time (HH:mm):</strong></label>
        <input
          type="time"
          id="timeInput"
          className="form-control"
          value={enteredTime}
          onChange={(e) => setEnteredTime(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="locationSelect"><strong>Select Location:</strong></label>
        <select
          id="locationSelect"
          className="form-control"
          value={locationOut}
          onChange={(e) => setLocationOut(e.target.value)}
        >
          <option value="">Select</option>
          <option value="Office">Office</option>
          <option value="Client">Client</option>
          <option value="Home">Home</option>
          <option value="Any Other">Any Other</option>
        </select>
        {locationOut === 'Any Other' && (
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Specify the location"
            value={otherLocation}
            onChange={(e) => setOtherLocation(e.target.value)}
          />
        )}
      </div>
      <button
        className="btn btn-success mt-2"
        onClick={handleDayOut}
        disabled={
          !locationOut ||
          (locationOut === 'Any Other' && !otherLocation) ||
          !enteredTime ||
          !latestDate
        }
      >
        Day Out
      </button>
    </>)
    :
        <div className="alert alert-danger mt-3">
        <strong>No Date to Day Out. Please Day in first</strong>
        </div>
    }
      {!outBefireIn && dayOut && (<>
        <div className="mt-2">
          <p>
            <strong>Hello, {employeeData?.FirstName} {employeeData?.LastName}</strong>
          </p>
          <p>
            <strong>Day Out:</strong> {dayOut.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })}
          </p>
          {locationOut && (
            <>
              <p>
                <strong>Place:</strong> {locationOut === 'Any Other' ? otherLocation : locationOut}
              </p>
            </>
          )}
          {isOnTime !== null && (
            <>
              {isOnTime ? (
                <p><strong>You are on time</strong></p>
              ) : (
                <div className="alert alert-danger mt-3">
                  <strong>
                    You left early by {Math.floor(earlyMinutes / 60)} hours and {earlyMinutes % 60} minutes
                  </strong>
                  <br />
                  <strong>{requestInfo}</strong>
                  </div>
              )}
            </>
          )}
        </div>
              </>
      )}
      {requestInfo && (<>
        <div className="mt-2">
          <p className="alert alert-info">{requestInfo}</p>
        </div>
        {pendingTasks && 
              <div>
                <h6>Pending Tasks</h6>
              <table {...getTableProps()} className="table table-striped">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              </div>}
              </>
      )}
    </div>
  );
};

export default DayOut;
