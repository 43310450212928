import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/Timesheet.css';
import Swal from 'sweetalert2';
import { Buffer } from 'buffer';
const moment = require('moment');
const momentTimezone = require('moment-timezone');

const FloatingDailySummary = () => {
  // Example date options for the select input
  const email = sessionStorage.getItem('email'); // Get email from session storage
  const [currentDate, setCurrentDate] = useState({ day: '', month: '', year: '', formattedDate: '' });
  const [todayDate, setTodayDate] = useState('');
  const [weekNumber, setWeekNumber] = useState(0);
  const [weekStartEnd, setWeekStartEnd] = useState({ startDate: '', endDate: '' });
  const [employeeData, setemployeeData] = useState({
    FirstName: '',
    LastName: '',
    Address: '',
    ContactNo: '',
    Email: '',
    Password: '',
    MonthlyRemuneration: '',
    Position: '',
    MonthlyLeaves: '',
    SignImage: null,
    empid: '',
    hoursType: '',
    floatingHours: '',
    ScheduleIn: '',
    ScheduleOut: ''
  });
  const [timeSheetDate, setTimeSheetDate] = useState('');
  const [availableDates, setAvailableDates] = useState([]);
  const [openingHours, setOpeningHours] = useState(0);
  const [openingHoursString, setOpeningHoursString] = useState('');
  const [closingHours, setClosingHours] = useState(0);
  const [closingHoursString, setClosingHoursString] = useState('');
  const [dayOutTime, setDayOutTime] = useState(0);
  const [dayInTime, setDayInTime] = useState(0);
  const [dayOutTimeString, setDayOutTimeString] = useState('');
  const [dayInTimeString, setDayInTimeString] = useState('');
  const [dayTime,setDayTime] = useState(0);
  const [dayTimeString, setDayTimeString] = useState('');
  const [lessOneHourSheetNotMade, setLessOneHourSheetNotMade] = useState(0);
  const [actualDayTime, setActualDayTime] = useState(0);
  const [actualDayTimeString, setActualDayTimeString] = useState('');
  const [travelTime, setTravelTime] = useState(0);
  const [travelTimeString, setTravelTimeString] = useState('');
  const [numOfClientVisit,setNumOfClientVisit] = useState(0);
  const [lunchTime, setLunchTime] = useState(0);
  const [lunchTimeString, setLunchTimeString] = useState('');
  const [otherUnproductiveTime, setOtherUnproductiveTime] = useState(0);
  const [otherUnproductiveTimeString, setOtherUnproductiveTimeString] = useState('');
  const [totalUnproductiveTime, setTotalUnproductiveTime] = useState(0);
  const [totalUnproductiveTimeString, setTotalUnproductiveTimeString] = useState('');
  const [totalProductiveTime, setTotalProductiveTime] = useState(0);
  const [totalProductiveTimeString, setTotalProductiveTimeString] = useState('');
  const [totalTaskTime, setTotalTaskTime] = useState(0);
  const [totalTaskTimeString, setTotalTaskTimeString] = useState('');
  const [tasks, setTasks] = useState([]);
  const [clientNames, setClientNames] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Set the current date in the format you prefer (e.g., 'YYYY-MM-DD')
    const today = moment().format('YYYY-MM-DD');
    setTodayDate(today);
}, []);

useEffect(() => {
  // Fetch the available dates from the backend
  axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/checkLastTimesheetMade', { params: { email } })
      .then(response => {
          if (response.data.result === 1) {
              setAvailableDates(response.data.dates);
          } else {
              setAvailableDates([]);
          }
      })
      .catch(error => {
          console.error('Error fetching available dates:', error);
      });
}, []);

  const calculateDateDetails = (date) => {
    const day = date.toLocaleString('default', { weekday: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const dayOfMonth = date.getDate().toString().padStart(2, '0');
    const monthOfYear = (date.getMonth() + 1).toString().padStart(2, '0');
    const formattedDate = `${dayOfMonth}-${monthOfYear}-${year}`;
    
    setCurrentDate({ day, month, year, formattedDate });
    setWeekNumber(getWeekNumber(date));
    const { startDate, endDate } = getWeekStartEnd(date);
    setWeekStartEnd({ startDate: startDate.toDateString(), endDate: endDate.toDateString() });
  };
  
  const TimeDisplay = (totalMinutes ) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}`;
    
  };
  const convertToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return (hours * 60) + minutes;
  };
  const getClient = async (client) => {
    try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getClientByCode', {
            params: {
                client,
            },
        });

        if (response.data.length > 0) {
            return response.data[0].TradeName;
        } else {
            return 'Client not found';
        }
    } catch (error) {
        console.error('Error fetching client:', error);
        return 'Error fetching client';
    }
};
const groupTasks = (tasks) => {
  const groupedTasks = {};

  tasks.forEach((task) => {
      const key = task.mid;

      if (!groupedTasks[key]) {
          groupedTasks[key] = {
              mid: task.mid,
              reportingDate: task.reportingDate,
              mainTask: task.mainTask[0],
              clientCode: task.CC,
              subTasks: [],
              remarks: [],
              taskTimeInMinutes: task.taskTimeInMinutes,
              travelTime: task.travelTime,
              taskPeriods: [],
          };
      }

      groupedTasks[key].subTasks.push(task.subTask);
      groupedTasks[key].remarks.push(task.remarks);
      const taskFromDate = moment(task.taskfrom);
      const taskToDate = moment(task.taskto);

      if (!taskFromDate.isValid() || taskFromDate.year() < 2000) {
          groupedTasks[key].taskPeriods.push('No Period');
      } else {
          groupedTasks[key].taskPeriods.push(
              `${taskFromDate.format('DD-MMM-YY')} - ${taskToDate.format('DD-MMM-YY')}`
          );
      }
  });

  return Object.values(groupedTasks).sort((a, b) => {
      return moment(b.reportingDate).diff(moment(a.reportingDate));
  });
};
const truncateRemarks = (remarks, maxLength = 10) => {
  // Join remarks into a single string
  const fullRemarks = remarks.join(' '); 
  // Check if the length exceeds the maxLength
  return fullRemarks.length > maxLength 
      ? `${fullRemarks.substring(0, maxLength)}...` // Truncate and add ellipsis
      : fullRemarks; // Return full remarks if within limit
}; 
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata`, {
          params: { email }
        });
        if (response.data && response.data.length > 0) {
          setemployeeData(response.data[0]);


       // Assuming response.data is an array with a single user object
        } else {
          console.error('No user data found for the provided email');
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };
    
    fetchProfileData();
  }, [email]);

  let dayTimeScope;
  const fetchTasks = async (date) => {
    try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/completedDayTasksByUser', {
            email: email,
            startDate:date,
            endDate:date,
        });
        const tasks = response.data;

        setTasks(tasks);

        const clientNamesTemp = {};
        for (const task of tasks) {
            const tradeName = await getClient(task.CC);
            clientNamesTemp[task.CC] = tradeName;
        }

        setClientNames(clientNamesTemp);
    } catch (error) {
        console.error("Error fetching tasks:", error);
    }
};
  const handleChange = async (event) => {
    const { name, value } = event.target;
    if(name === "date"){
      const selectedDate = event.target.value;
      fetchTasks(selectedDate);
    const weekdate = new Date(selectedDate);
    calculateDateDetails(weekdate);
    setTimeSheetDate(selectedDate); // Update state with the selected date

    try {
        // Fetch inTime and outTime
        const timeResponse = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetch-time', {
            params: {
                date: selectedDate,
                email: email
            }
        });
        if (timeResponse.data) {
            const dayOutTime = moment.utc(timeResponse.data.outTime).format('HH:mm');
            const dayInTime = moment.utc(timeResponse.data.inTime).format('HH:mm');

            const [dayOutTimehours, dayOutTimeminutes] = dayOutTime.split(':').map(Number);
            const dayOutTotalMinutes = dayOutTimehours * 60 + dayOutTimeminutes;
            setDayOutTime(dayOutTotalMinutes)
            setDayOutTimeString(TimeDisplay(dayOutTotalMinutes))

            const [dayInTimehours, dayInTimeminutes] = dayInTime.split(':').map(Number);
            const dayIntotalMinutes = dayInTimehours * 60 + dayInTimeminutes;
            setDayInTime(dayIntotalMinutes)
            setDayInTimeString(TimeDisplay(dayIntotalMinutes))

            setDayTime(dayOutTotalMinutes-dayIntotalMinutes);
            setDayTimeString(TimeDisplay(dayOutTotalMinutes-dayIntotalMinutes))
            setActualDayTime(dayOutTotalMinutes-dayIntotalMinutes);
            setActualDayTimeString(TimeDisplay(dayOutTotalMinutes-dayIntotalMinutes))
            dayTimeScope = dayOutTotalMinutes-dayIntotalMinutes;
            if (isNaN(dayOutTotalMinutes)) {
              Swal.fire({
                  icon: 'warning',
                  title: 'Day Out Required',
                  text: 'Please Day Out First',
              }).then((result) => {
                  if (result.isConfirmed) {
                      navigate('/userDashboard/employeedayout');
                  }
              });
          }
            
        } else {
            setDayOutTime(0);
            setDayInTime(0);
            setDayTime(0);
            setActualDayTime(0)
            setDayOutTimeString(TimeDisplay(0))
            setDayInTimeString(TimeDisplay(0))
            setDayTimeString(TimeDisplay(0))
            setActualDayTimeString(TimeDisplay(0))
            dayTimeScope=0
        }
        const dateObject = new Date(selectedDate);
        const isFirstDateOfMonth = dateObject.getDate() === 1;
        let balanceHourScope;
        if (isFirstDateOfMonth) {
          // Set opening hours to floatingHours * 60
          setOpeningHours(employeeData.floatingHours * 60);
          setOpeningHoursString(TimeDisplay(employeeData.floatingHours * 60));
        } else {
          try {
            const balanceHourResponse = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetchBalanceHours', {
              email: email,
            });
        
            if (balanceHourResponse.data) {
              setOpeningHours(balanceHourResponse.data.closingHours);
              setOpeningHoursString(TimeDisplay(balanceHourResponse.data.closingHours));
              balanceHourScope = balanceHourResponse.data.closingHours;
            } else {
              setOpeningHours(0);
              setOpeningHoursString(TimeDisplay(0)); // Assuming it's in minutes
              balanceHourScope = 0
            }
          } catch (error) {
            console.error("Error fetching balance hours:", error);
            setOpeningHours(0);
            setOpeningHoursString(TimeDisplay(0));
            balanceHourScope = 0
          }
        } 

        // Fetch sum of travelTime and count of clientid
        const visitSummaryResponse = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/visit-summary', {
            email: email,
            date: selectedDate
        });
        
        if (visitSummaryResponse.data) {
            // Set travel time and number of client visits
            setTravelTime((visitSummaryResponse.data.totalTravelTime));
            setTravelTimeString(TimeDisplay(visitSummaryResponse.data.totalTravelTime)) // Assuming it's in minutes
            setNumOfClientVisit(visitSummaryResponse.data.clientCount);
        } else {
            setTravelTime(0);
            setTravelTimeString(TimeDisplay(0)) // Assuming it's in minutes
            setNumOfClientVisit(0);
        }

          const taskTimeResponse = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/task-summary', {
            email: email,
            date: selectedDate
        }
          );
           let totalTaskTimeScope;
            if(taskTimeResponse.data){
              totalTaskTimeScope = taskTimeResponse.data.totalTaskTime;
              setTotalTaskTime(totalTaskTimeScope)
               setTotalTaskTimeString(TimeDisplay(totalTaskTimeScope));
               setClosingHours(balanceHourScope-totalTaskTimeScope)
                setClosingHoursString(TimeDisplay(balanceHourScope-totalTaskTimeScope))
              }

            }
     catch (error) {
                console.error('Error fetching time data or summary:', error);
            }
          } 
          if(name === 'lunchTime') {
            let lunchTimeValue = event.target.value;
            setLunchTimeString(lunchTimeValue)
            setLunchTime(convertToMinutes(lunchTimeValue))
            let lunchTimeValueMinutes = convertToMinutes(lunchTimeValue);
            const TravelTime = Number(travelTime);
            let OtherUnproductiveTimeValue = dayTime -  lunchTimeValueMinutes - TravelTime - totalTaskTime;
            setOtherUnproductiveTime(OtherUnproductiveTimeValue);
            setOtherUnproductiveTimeString(TimeDisplay(OtherUnproductiveTimeValue))
  
            // Calculate total unproductive time
            const TotalUnproductiveTime = TravelTime + OtherUnproductiveTimeValue + lunchTimeValueMinutes;
            
            // Set the state with the calculated total unproductive time
            setTotalUnproductiveTime(TotalUnproductiveTime);
            setTotalUnproductiveTimeString(TimeDisplay(TotalUnproductiveTime))              
            setTotalProductiveTime(dayTime-TotalUnproductiveTime)
            setTotalProductiveTimeString(TimeDisplay(dayTime-TotalUnproductiveTime))
            }
          }

          const getWeekStartEnd = (date) => {
            const firstDayOfWeek = new Date(date.setDate(date.getDate() - date.getDay() + 1));
            const lastDayOfWeek = new Date(firstDayOfWeek);
            lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
            return { startDate: firstDayOfWeek, endDate: lastDayOfWeek };
          };

          const getWeekNumber = (date) => {
            const startOfYear = new Date(date.getFullYear(), 0, 1);
            const pastDaysOfYear = (date - startOfYear) / 86400000;
            return Math.ceil((pastDaysOfYear + startOfYear.getDay()) / 7);
          };
          const fullname=employeeData.FirstName +' '+ employeeData.LastName
          const ScheduleIn = momentTimezone.utc(employeeData.ScheduleIn).format('hh:mm A');
          const ScheduleOut = momentTimezone.utc(employeeData.ScheduleOut).format('hh:mm A');
          
          const scheduleTime=ScheduleIn +'-'+ ScheduleOut
        
          const handleSubmit = async (event) => {
            event.preventDefault();
            if(!timeSheetDate){
              Swal.fire({
                icon: 'error',
                title: 'Date is required',
                text: 'Please enter a date',
              });
              return
            }
            const isValidFormatLunchTime = /^([01]\d|2[0-3]):([0-5]\d)$/.test(lunchTimeString);
            if(!isValidFormatLunchTime){
              Swal.fire({
                icon: 'error',
                title: 'Invalid Format for Lunch Time',
                text: 'Please enter time in HH:MM format',
              });
              return
            }
            const isValidFormatOtherUnproductive = /^([01]\d|2[0-3]):([0-5]\d)$/.test(otherUnproductiveTimeString);
            if(!isValidFormatOtherUnproductive){
              Swal.fire({
                icon: 'error',
                title: 'Invalid Format for Other Unproductive Time',
                text: 'Please enter time in HH:MM format',
              });
              return
            }

            const payload = {
              empid:employeeData.empid,
              email: email,
              fullname:fullname,
              hoursType:employeeData.hoursType,
              timeSheetDate: timeSheetDate,
              weekNumber:weekNumber,
              dayOutTime:dayOutTime,
              dayInTime:dayInTime,
              dayTime:dayTime,
              lessOneHourSheetNotMade:lessOneHourSheetNotMade,
              actualDayTime:actualDayTime,
              numOfClientVisit:numOfClientVisit,
              travelTime:travelTime,
              lunchTime:lunchTime,
              otherUnproductiveTime:otherUnproductiveTime,
              totalUnproductiveTime:totalUnproductiveTime,
              totalProductiveTime:totalProductiveTime,
              totalTaskTime:totalTaskTime,
              submittedBy:employeeData.empid,
              openingHours:openingHours,
              closingHours:closingHours
            };
            
            
            try {
              const insertTimesheetResponse = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/insert-timesheet-floating', payload);
              const responseMessage = insertTimesheetResponse.data.message;
              
              // Determine icon type based on the message content
              const iconType = responseMessage.includes('Timesheet already exists')
                ? 'error'
                : 'success';
            
              Swal.fire({
                toast: true,
                position: 'top-end',
                icon: iconType,
                text: responseMessage,
                showConfirmButton: false,
                timer: 3000
              });
            } catch (error) {
              console.error('There was an error submitting the timesheet data!', error);
            
              Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                text: 'An error occurred while submitting. Please try again.',
                showConfirmButton: false,
                timer: 3000
              });
            }
          };
          const groupedTasks = groupTasks(tasks);

          return (
            <div className="container mt-5">
              <h3 className="mb-4">Daily Summary</h3>
              <div className="table-responsive">
                <table className="table table-hover table-bordered table-striped">
                  <thead className="thead-dark">
                    <tr>
                      <th className="text-center">Date</th>
                      <th className="text-center">Op. Hours</th>
                      <th className="text-center">Day In</th>
                      <th className="text-center">Day Out</th>
                      <th className="text-center">Day Time</th>
                      <th className="text-center">Adjusted Day Time</th>
                      <th className="text-center">No. Of Visits</th>
                      <th className="text-center">Travel Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">
                        <select
                          className="form-control text-center mx-auto"
                          name="date"
                          style={{ width: '150px' }}
                          value={timeSheetDate}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Date</option>
                          {availableDates.length === 0 ? (
                            <option value="">No dates available</option>
                          ) : (
                            availableDates.map((date) => (
                              <option key={date} value={date}>
                                {new Date(date).toLocaleDateString('en-GB', {
                                    day: '2-digit',
                                    month: 'short',
                                    year: '2-digit',
                                })}
                              </option>
                            ))
                          )}
                        </select>
                      </td>
                      <td className="text-center">{openingHoursString}</td>
                      <td className="text-center">{dayInTimeString}</td>
                      <td className="text-center">{dayOutTimeString}</td>
                      <td className="text-center">{dayTimeString}</td>
                      <td className="text-center">{actualDayTimeString}</td>
                      <td className="text-center">{numOfClientVisit}</td>
                      <td className="text-center">{travelTimeString}</td>
                    </tr>
                    <tr>
                      <th className="text-center">Lunch Time</th>
                      <th className="text-center">UnP Time</th>
                      <th className="text-center">Total UnP Time</th>
                      <th className="text-center">Total Prod Time</th>
                      <th className="text-center">Total Task Time</th>
                      <th className="text-center">Cl. Hours</th>
                      <th colSpan='2' className="text-center">Action</th>
                    </tr>
                    <tr>
                      
                      <td className="text-center">
                        <input
                          type="text"
                          name="lunchTime"
                          className="form-control mx-auto text-center"
                          style={{ width: '100px' }}
                          placeholder="HH:MM"
                          onChange={handleChange}
                          required
                        />
                      </td>
                      <td className="text-center">{otherUnproductiveTimeString}</td>
                      <td className="text-center">{totalUnproductiveTimeString}</td>
                      <td className="text-center">{totalProductiveTimeString}</td>
                      <td className="text-center">{totalTaskTimeString}</td>
                      <td className="text-center">{closingHoursString}</td>
                      <td className="text-center" colSpan={2}><button type="submit" className="btn btn-success" onClick={handleSubmit}>Submit</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mt-4">
                {groupedTasks.length > 0 ? (
                    <table className="table table-striped mt-4">
                        <thead style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
                            <tr>
                                <th>Date</th>
                                <th>Client</th>
                                <th>Main Task Name</th>
                                <th>Sub Task </th>
                                <th>Task Period</th>
                                <th>Task Time</th>
                                <th>Travel Time</th>
                                <th>Remarks</th>
                                
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '12px', verticalAlign: 'baseline' }}>
    {groupedTasks.map((task, index) => {
        return (
            <tr key={index}>
                <td>{moment(task.reportingDate[0]).format('DD-MMM-YYYY')}</td>
                <td>{clientNames[task.clientCode] || 'Loading...'}</td>
                <td>{task.mainTask}</td>
                <td>{task.subTasks.join(',')}</td>
                <td>{task.taskPeriods.join(',')}</td>
                <td>{TimeDisplay(task.taskTimeInMinutes)}</td>
                <td>{task.travelTime}</td>
                <td>
                                        <span
                                            data-bs-toggle="tooltip"
                                            title={task.remarks.join(' ')} // Full remarks text
                                            className="d-inline-block"
                                        >
                                            {truncateRemarks(task.remarks)} {/* Truncated remarks */}
                                        </span>
                                    </td>
            </tr>
        );
    })}
</tbody>


                    </table>
                ) : (
                    <p>No tasks found for the selected date.</p>
                )}
            </div>
            </div>
          );
        };
        
        export default FloatingDailySummary;
