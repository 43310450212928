import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import DateRangePickerComponent from './CustomDateRangePicker';
import Swal from "sweetalert2";
import { useTable, useSortBy, useGlobalFilter } from 'react-table'; // Import the hooks for sorting and global filtering
import moment from "moment";
import * as XLSX from "xlsx"; // Import xlsx library

// TimeDisplay function to format minutes into HH:MM format
const TimeDisplay = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.round(totalMinutes % 60);
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(Math.abs(minutes)).padStart(2, '0');
  
  return `${formattedHours}:${formattedMinutes}`;
};

const SurplusDeficiencyReport = () => {
  const [member, setMember] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]); // State for date range selection
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  useEffect(() => {
    // Fetch users from backend
    axios
      .get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/users')
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const [startDate, endDate] = dateRange; // Destructure start and end dates from dateRange
    if (!member) {
      Swal.fire({
        icon: 'error',
        text: 'Please select a member',
      });
      return;
    }
    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Date Range',
        text: 'End date should be after the start date.',
      });
      return;
    }
    try {
      const response = await axios.get("https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getSurplusDeficiency", {
        params: {
          member,
          startDate,
          endDate,
        },
      });

      // Assuming response contains the data we need
      setReportData(response.data);
      console.log(response.data);
      
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false);
    }
  };

  // React Table columns definition
  const columns = React.useMemo(() => {
    const hoursType = reportData.length > 0 ? reportData[0]?.hoursType : null; // Check hoursType from first row
  
    const formatDateWithDay = (date) => {
      return `${moment(date).format("DD-MMM-YY")} (${moment(date).format("dddd")})`; // Example: "04-Feb-25 (Tuesday)"
    };
  
    if (hoursType === "fixed") {
      return [
        {
          Header: "Date",
          accessor: "timesheetDate",
          Cell: ({ value }) => formatDateWithDay(value),
        },
        {
          Header: "Opening Deficiency (HH:MM)",
          accessor: "openingDeficiency",
          Cell: ({ value }) => TimeDisplay(value),
        },
        {
          Header: "Closing Deficiency (HH:MM)",
          accessor: "closingDeficiency",
          Cell: ({ value }) => TimeDisplay(value),
        },
        {
          Header: "Opening Surplus (HH:MM)",
          accessor: "openingSurplus",
          Cell: ({ value }) => TimeDisplay(value),
        },
        {
          Header: "Closing Surplus (HH:MM)",
          accessor: "closingSurplus",
          Cell: ({ value }) => TimeDisplay(value),
        },
      ];
    } else if (hoursType === "floating") {
      return [
        {
          Header: "Date",
          accessor: "timesheetDate",
          Cell: ({ value }) => formatDateWithDay(value),
        },
        {
          Header: "Opening Hours (HH:MM)",
          accessor: "openingHours",
          Cell: ({ value }) => TimeDisplay(value),
        },
        {
          Header: "Closing Hours (HH:MM)",
          accessor: "closingHours",
          Cell: ({ value }) => TimeDisplay(value),
        },
      ];
    } else {
      return []; // Default empty columns in case hoursType is missing
    }
  }, [reportData]);
   
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: reportData || [], // Ensure data is at least an empty array
    },
    useGlobalFilter, // Hook for global search
    useSortBy // Hook for sorting
  );  

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setGlobalFilter(e.target.value); // Apply global search
  };

  // Export data to Excel
  const handleExport = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
  
    // Create headers for the input fields (Select Member and Date Range)
    const memberInfo = [
      ["Select Member:", member ? `${member} - ${users.find(user => user.empid === member)?.FirstName} ${users.find(user => user.empid === member)?.LastName}` : "Not Selected"],
      ["Date Range:", dateRange[0] && dateRange[1] ? `${moment(dateRange[0]).format('DD-MMM-YY')} to ${moment(dateRange[1]).format('DD-MMM-YY')}` : "Not Selected"]
    ];
  
    // Create a worksheet for the input fields
    const wsMemberInfo = XLSX.utils.aoa_to_sheet(memberInfo);
    XLSX.utils.book_append_sheet(wb, wsMemberInfo, "SurplusDeficiencyReport"); // Add the input fields sheet
  
    // Create headers for the table
    const tableHeaders = [
      ['Date', 'Opening Deficiency', 'Closing Deficiency', 'Opening Surplus', 'Closing Surplus']
    ];
  
    // Prepare table data
    const tableData = reportData.map(row => [
      moment(row.timesheetDate).format('DD-MMM-YY'),
      TimeDisplay(row.openingDeficiency),
      TimeDisplay(row.closingDeficiency),
      TimeDisplay(row.openingSurplus),
      TimeDisplay(row.closingSurplus)
    ]);
  
    // Create a worksheet for the table
    const wsTable = XLSX.utils.aoa_to_sheet(tableHeaders.concat(tableData)); // Combine headers and data
    XLSX.utils.book_append_sheet(wb, wsTable, "Report Data"); // Add the data sheet
  
    // Write the workbook to a file
    XLSX.writeFile(wb, "SurplusDeficiencyReport.xlsx");
  };
  

  return (
    <div className="container mt-5">
      <h2 className="">Surplus/Deficiency Report</h2>
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="member" className="form-label">
              Select Member
            </label>
            <select
              id="member"
              className="form-select"
              value={member}
              onChange={(e) => setMember(e.target.value)}
              required
            >
              <option value="">Select Member</option>
              {users.map((user) => (
                <option key={user.empid} value={user.empid}>
                  {user.empid} - {user.FirstName} {user.LastName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6">
            <label className="form-label">Date Range:</label>
            <DateRangePickerComponent value={dateRange} onChange={setDateRange} />
          </div>
        </div>
        <button type="submit" className="btn btn-success mt-3 me-2">
          Generate Report
        </button>
        <button className="btn btn-secondary  mt-3" onClick={handleExport}>
            Export to Excel
          </button>
      </form>

      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      {loading && <p>Loading...</p>}

      {reportData.length > 0 && !loading && (
        <div className="table-responsive">
          {/* Table using React Table */}
          <table className="table table-bordered table-striped" {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add sorting indicators */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SurplusDeficiencyReport;
