import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import moment from 'moment';
import * as XLSX from 'xlsx'; // Import xlsx

const ShowEmployeeTasks = () => {
  const [pendingTasks, setPendingTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('pending'); // 🔥 Track active tab
  const [employeeData, setEmployeeData] = useState({});
  
  const email = sessionStorage.getItem('email');

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata`, { params: { email } });
        if (response.data && response.data.length > 0) {
          setEmployeeData(response.data[0]);
        } else {
          console.error('No user data found for the provided email');
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };
    fetchProfileData();
  }, []);
  let isMGEmployee = null;
  if (email) {
    isMGEmployee = employeeData && employeeData.empid && typeof employeeData.empid === 'string' && employeeData.empid.startsWith('MG');
  } else {
    isMGEmployee = null;
  }
  let isTM003 = null;
  if(email){
    isTM003 = employeeData && employeeData.empid && typeof employeeData.empid === 'string' && employeeData.empid.startsWith('TM-003');}
    else{
      isTM003=null;
    }

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const pendingResponse = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/showemployeetasks');
        const completedResponse = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/showcompletedtasks');
        
        setPendingTasks(groupTasks(pendingResponse.data));
        setCompletedTasks(groupTasks(completedResponse.data));
      } catch (error) {
        Swal.fire('Error', 'There was an error fetching tasks!', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, []);

  const groupTasks = (tasks) => {
    const groupedData = tasks.reduce((acc, task) => {
      const key = `${task.empid}-${task.clientid}-${task.mainTask}-${task.remarks || '-'}`;
      if (!acc[key]) {
        acc[key] = {
          ID: task.ID,
          empid: task.empid[0],
          FirstName: task.FirstName,
          LastName: task.LastName,
          clientid: task.clientid,
          TradeName: task.TradeName,
          mainTask: task.mainTask || '-',
          taskname: [task.taskname],
          AssignedBy: task.AssignedBy,
          deadline: task.deadline ? moment(task.deadline).format('DD-MM-YYYY') : '-',
          remarks: task.remarks || '-',
        };
      } else {
        acc[key].taskname.push(task.taskname);
      }
      return acc;
    }, {});

    return Object.values(groupedData);
  };

  const handleDelete = (row) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You are about to delete this task for employee ${row.empid}. This action cannot be undone.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deleteAssignTask', { taskId: row.ID }, { headers: { 'Content-Type': 'application/json' } });
          Swal.fire('Deleted!', 'The task has been deleted.', 'success').then(() => {
            window.location.reload();
          });
        } catch (error) {
          Swal.fire('Error', 'Failed to delete the task!', 'error');
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      { Header: 'TM ID', accessor: 'empid' },
      { Header: 'TM Name', accessor: (row) => `${row.FirstName} ${row.LastName}` },
      { Header: 'CC', accessor: 'clientid' },
      { Header: 'Trade Name', accessor: 'TradeName' },
      { Header: 'Main Task', accessor: 'mainTask' },
      { Header: 'Sub Task', accessor: (row) => row.taskname.join(', ') },
      { Header: 'Remarks', accessor: (row) => row.remarks || '-' },
      { Header: 'Deadline', accessor: 'deadline' },
      { Header: 'Assigned By', accessor: 'AssignedBy' },
      (activeTab === 'pending' && (isMGEmployee || isTM003))
        ? {
        Header: 'Action',
        Cell: ({ row }) => (
          <button className="btn btn-danger" onClick={() => handleDelete(row.original)}>
            Delete
          </button>
        ),
      }:null,
    ].filter(Boolean),
    [activeTab]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    { columns, data: activeTab === 'pending' ? pendingTasks : completedTasks },
    useGlobalFilter,
    usePagination
  );

  return (
    <div className="container">
      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <h3 className="mt-5">Employee Tasks</h3>

          {/* 🔥 Tabs for Pending and Completed */}
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 'pending' ? 'active' : ''}`}
                onClick={() => setActiveTab('pending')}
              >
                Pending Tasks
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 'completed' ? 'active' : ''}`}
                onClick={() => setActiveTab('completed')}
              >
                Completed Tasks
              </button>
            </li>
          </ul>

          <input type="text" placeholder="Search..." className="form-control my-3" onChange={(e) => setGlobalFilter(e.target.value)} />

          {/* Table */}
          <div className="table-responsive">
            <table {...getTableProps()} className="table table-striped" style={{fontSize:'13px', verticalAlign:'baseline'}}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default ShowEmployeeTasks;
