import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEyeSlash, faEye, faSync, faEdit, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
const MemoEditModal = ({ CC, empid, onClose }) => {
    const [memoContent, setMemoContent] = useState([{ id: null, applicableReturn: 'R1', remarks: '', month: '', resolved: false }]);
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        const fetchMemoDetails = async () => {
            try {
                const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getGSTMemoDetails?CC=${CC}`);
                const fetchedMemoContent = response.data.memo || [];
                if (fetchedMemoContent.length === 0) {
                    setMemoContent([{ id: null, applicableReturn: 'R1', remarks: '', month: '', resolved: false }]); // Set to one empty row
                } else {
                    setMemoContent(fetchedMemoContent);
                }
                
            } catch (error) {
                console.error('Error fetching memo data:', error);
                Swal.fire({
                    toast: true,
                    text: 'There was a problem fetching memo data.',
                    icon: 'error',
                    position: 'top-end',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        };

        fetchMemoDetails();
    }, [CC]);

    const handleAddRow = () => {
        setMemoContent([...memoContent, { id: null, applicableReturn: 'R1', remarks: '', month: '', resolved: false }]);
    };

    const handleRemoveRow = async (index) => {
        const rowToRemove = memoContent[index];

        // Check if the row is empty
        const isEmptyRow = !rowToRemove.remarks && !rowToRemove.month;

        if (isEmptyRow) {
            // Remove the row immediately if it's empty
            const newMemoContent = memoContent.filter((_, idx) => idx !== index);
            setMemoContent(newMemoContent);
        } else {
            // Ask for confirmation if the row is filled
            const confirmed = await Swal.fire({
                title: 'Are you sure?',
                text: 'This row will be deleted permanently!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it',
            });

            if (confirmed.isConfirmed) {
                try {
                    await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deleteMemo', {
                        id: rowToRemove.id, // Use the unique ID to delete the row
                    });
                    const newMemoContent = memoContent.filter((_, idx) => idx !== index);
                    setMemoContent(newMemoContent);
                    Swal.fire({
                        toast: true,
                        text: 'The row has been deleted.',
                        icon: 'success',
                        position: 'top-end',
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                    });
                } catch (error) {
                    console.error('Error deleting memo row:', error);
                    Swal.fire({
                        toast: true,
                        text: 'There was a problem deleting the row.',
                        icon: 'error',
                        position: 'top-end',
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                    });
                }
            }
        }
    };
    const handleRefresh = () => {
        setRefresh((prevRefresh) => !prevRefresh);
      };
    const handleResolve = async (index) => {
        const newMemoContent = [...memoContent];
        const rowToResolve = newMemoContent[index];

        try {
            await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/resolveMemo', {
                id: rowToResolve.id, // Add ID for resolving
                CC,
                empid: empid[0],
                applicableReturn: rowToResolve.applicableReturn,
                remarks: rowToResolve.remarks,
                month: rowToResolve.month,
            });

            newMemoContent[index].resolved = true;
            setMemoContent(newMemoContent);

            Swal.fire({
                toast: true,
                text: 'The row has been marked as resolved.',
                icon: 'success',
                position: 'top-end',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
                willClose: () => {
                    handleRefresh();
                },
            });
        } catch (error) {
            console.error('Error resolving memo row:', error);
            Swal.fire({
                toast: true,
                text: 'There was a problem marking the row as resolved.',
                icon: 'error',
                position: 'top-end',
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };

    const handleInputChange = (index, field, value) => {
        const newMemoContent = [...memoContent];
        newMemoContent[index][field] = value;
        setMemoContent(newMemoContent);
    };

    const handleSubmit = async () => {
        try {
            await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateMemoData', {
                CC,
                empid: empid[0],
                memo: memoContent,
            });
            Swal.fire({
                toast: true,
                text: 'Memo has been updated.',
                icon: 'success',
                position: 'top-end',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
            });
        } catch (error) {
            console.error('Error updating memo:', error);
            Swal.fire({
                toast: true,
                text: 'There was a problem saving your changes.',
                icon: 'error',
                position: 'top-end',
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };

    return (
        <div className="modal-content">
            <div className="modal-body">
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Particulars</th>
                                <th>Remarks</th>
                                <th>Month</th>
                                <th>AddedBy</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {memoContent.length === 0 ? (
                                <tr>
                                    <td>
                                        <select className="form-select" value={'R1'}>
                                            <option value="R1">R1</option>
                                            <option value="IFF">IFF</option>
                                            <option value="3B">3B</option>
                                            <option value="Tax">Tax</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value={memoContent.remarks} name='remarks' />
                                    </td>
                                    <td>
                                        <input type="month" className="form-control" value={memoContent.month} name='month' />
                                    </td>
                                    <td>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={''}
                                                disabled={true}
                                            />
                                        </td>
                                    <td>
                                    <button className="btn btn-success btn-sm" onClick={handleAddRow}>
                <FontAwesomeIcon icon={faPlus} />
            </button>
                                        <button className="btn btn-warning btn-sm" onClick={() => handleResolve()} >
                                            Resolved
                                        </button>
                                    </td>
                                </tr>
                            ) : (
                                memoContent.map((item, index) => (
                                    <tr key={index} style={{ opacity: item.resolved ? 0.5 : 1 }}>
                                        <td>
                                            <select
                                                className="form-select"
                                                value={item.applicableReturn}
                                                onChange={(e) => handleInputChange(index, 'applicableReturn', e.target.value)}
                                                disabled={item.resolved}
                                            >
                                                <option value="R1">R1</option>
                                                <option value="IFF">IFF</option>
                                                <option value="3B">3B</option>
                                                <option value="Tax">Tax</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={item.remarks}
                                                onChange={(e) => handleInputChange(index, 'remarks', e.target.value)}
                                                disabled={item.resolved}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="month"
                                                className="form-control"
                                                value={item.month}
                                                onChange={(e) => handleInputChange(index, 'month', e.target.value)}
                                                disabled={item.resolved}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={`${(item.AddedByEmpid)||''}  ${item.AddedByDate?moment(item.AddedByDate).format('DD-MMM-YY'):''}`}
                                                disabled={true}
                                            />
                                        </td>
                                        <td>
    {!item.resolved ? (
        <>
            <button className="btn btn-success btn-sm" onClick={handleAddRow}>
                <FontAwesomeIcon icon={faPlus} />
            </button>
            <button className="btn btn-warning btn-sm" onClick={() => handleResolve(index)}>
                Resolved
            </button>
            {memoContent.length >= 1 && (
                
                <button className="btn btn-danger btn-sm" onClick={() => handleRemoveRow(index)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                </button>
            )}
        </>
    ) : (
        <>
         <button className="btn btn-success btn-sm" onClick={handleAddRow}>
                <FontAwesomeIcon icon={faPlus} />
            </button>
            <button className="btn btn-danger btn-sm" onClick={() => handleRemoveRow(index)}>
                <FontAwesomeIcon icon={faTrashAlt} />
            </button>
        </>
    )}
</td>

                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="modal-footer">
                <button className="btn btn-success" onClick={handleSubmit}>Update</button>
            </div>
        </div>
    );
};

export default MemoEditModal;
